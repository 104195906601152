import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Confirm({ title, message, handleClose, handleConfirm }) {
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="delete instance"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText dangerouslySetInnerHTML={{__html: message}} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>NO</Button>
        <Button onClick={handleConfirm} autoFocus>YES</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Confirm;
