import { useState } from 'react';
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
} from '@mui/material';

function ModifyInstance({ instance, handleClose, handleConfirm, error }) {
  const [name, setName] = useState(instance.name || '');
  const [ffKey, setFFKey] = useState(instance.ffKey || '');
  const [enabled, setEnabled] = useState(instance.enabled || false);
  const [userEmail, setUserEmail] = useState(instance.userEmail || '');

  const prepareFormData = () => {
    return {
      ...instance,
      name,
      ffKey,
      enabled,
      userEmail,
    };
  };
  const isFormReady = name.length > 0 && userEmail.length > 0;

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby={`${!instance.id ? 'Create' : 'Edit'} Feature flag`}
    >
      <DialogTitle>{!instance.id ? 'Create' : 'Edit'} Feature flag</DialogTitle>
      <DialogContent sx={{ width: 300, maxWidth: '600px' }}>
        {error && (
          <Alert severity="error" sx={{ marginBottom: 3 }}>{error}</Alert>
        )}
        <TextField
          label="Name"
          value={name}
          onChange={ev => setName(ev.target.value)}
          variant="standard"
          sx={{ width: '100%', marginBottom: 3 }}
        />
        <TextField
          label="Key"
          value={ffKey}
          onChange={ev => setFFKey(ev.target.value)}
          variant="standard"
          sx={{ width: '100%', marginBottom: 3 }}
        />
        <TextField
          label="Emails of the users for this "
          value={userEmail}
          onChange={ev => setUserEmail(ev.target.value)}
          variant="standard"
          sx={{ width: '100%', marginBottom: 3 }}
        />
        <FormGroup>
          <FormControlLabel control={<Switch checked={enabled} onChange={() => setEnabled(!enabled)} defaultChecked />}
                            label="Enabled" />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleConfirm(prepareFormData())} autoFocus disabled={!isFormReady}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModifyInstance;
