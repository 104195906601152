import { getSissiInvoicingUrl } from "config/server";

export async function getHousekeepingProtocols(params = {}) {
  const filter = params.filter || undefined;
  const $skip = params.skip || 0;
  const $limit = params.limit || 50;

  const urlSearchParams = new URLSearchParams({
    '$skip': $skip,
    '$limit': $limit,
    ...(filter ? { "searchable[$like]": `%${filter}%` } : {})
  });

  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocols`);
  url.search = urlSearchParams.toString();

  const res = await fetch(decodeURIComponent(url.toString()), {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return (await res.json());
}

export async function createExtraHousekeepingProtocols(data = {}) {
  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocols`);

  const res = await fetch(url.toString(), {
    method: 'POST',
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
    body: JSON.stringify(data),
  });
  if (res.status !== 201) {
    throw await res.json();
  }
  return (await res.json());
}

export async function deleteHousekeepingProtocol(data) {
  let url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocols`);
  if (!data.id) {
    return Promise.resolve(undefined);
  }

  const res = await fetch(`${url.toString()}/${data.id}`, {
    method: 'PATCH',
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
    body: JSON.stringify({
      deleted: new Date(),
      deletionReason: 'MANUALLY_DELETED',
    }),
  });
  if (res.status !== 201 && res.status !== 200) {
    throw await res.json();
  }
  return Promise.resolve();
}
