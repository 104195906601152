import { useEffect, useState } from 'react';

function useMultiSelection({ initialValue = {}, onChange }) {
  const [selected, setSelected] = useState(initialValue || {});

  useEffect(() => {
    onChange && onChange(selected);
  }, [selected]);
  
  const onSelectionChange = (id) => {
    const tmpSelected = { ...selected };

    // If the company is selected, deselect it
    if (selected[id]) {
      delete tmpSelected[id];
      setSelected(tmpSelected);
      return;
    }
    
    // Select the company and all its services
    tmpSelected[id] = true;
    setSelected(tmpSelected);
  }

  return {
    selected,
    setSelected,
    onSelectionChange,
  };
}

export default useMultiSelection;
