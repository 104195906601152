import { useEffect, useState } from 'react';

import { getInvoicingSystemConfig } from '../../api';
import { getCompanies } from '../../api/company';

function useInvoicingSystem() {
  const [ready, setReady] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [invoicingSystemConfig, setInvoicingSystemConfig] = useState(undefined);

  useEffect(() => {
    getInvoicingSystemConfig().then(async (invoicingSystemConfig) => {
      setInvoicingSystemConfig(invoicingSystemConfig);
      setReady(true);
    });
    getCompanies().then((companiesResponse) => {
      setCompanies(companiesResponse.data);
    });
  }, []);

  return {
    ready,
    setReady,
    companies,
    setCompanies,
    invoicingSystemConfig,
    setInvoicingSystemConfig,
  };
}

export default useInvoicingSystem;
