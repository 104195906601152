import React from 'react';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { BrowserRouter } from 'react-router-dom';
import { CacheProvider } from '@emotion/react';
import { createRoot } from 'react-dom/client';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import locale from 'date-fns/locale/en-GB';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import App from './app';
import theme, { muiCache } from './theme';

import './index.css';

// 👇️ IMPORTANT: use correct ID of your root element
// this is the ID of the div in your index.html file
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <BrowserRouter>
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
          <App />
        </LocalizationProvider>
      </ThemeProvider>
    </CacheProvider>
  </BrowserRouter>
);
