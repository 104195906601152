import { useEffect, useState } from 'react';

import {
  filterContacts,
  getCheckInAndCheckOut,
  getContactById,
  getServicesForApartmentCategory,
} from './api-services';
import { nightsBetween } from 'utils/date';

const timePickerValues = [];
const dateHours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const dateMinutes = ['00', '10', '20', '30', '40', '50'];
dateHours.forEach(hour => {
  dateMinutes.forEach(minutes => {
    timePickerValues.push(`${hour}:${minutes}`);
  });
});

const WEEK_DAYS = [
  { value: '1', label: 'MO', fullLabel: 'Monday' },
  { value: '2', label: 'TU', fullLabel: 'Tuesday' },
  { value: '3', label: 'WE', fullLabel: 'Wednesday' },
  { value: '4', label: 'TH', fullLabel: 'Thursday' },
  { value: '5', label: 'FR', fullLabel: 'Friday' },
  { value: '6', label: 'SA', fullLabel: 'Saturday' },
  { value: '0', label: 'SU', fullLabel: 'Sunday' },
];

// const NUMBER_LABELS = [
//     'first', 'second', 'third', 'fourth', 'fifth', 'sixth'
// ];

const REPEATABLE_KEYS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const REPEATABLE_OPTIONS = {
  [REPEATABLE_KEYS.DAY]: {
    label: 'Day',
    labelPlural: 'Days',
    value: REPEATABLE_KEYS.DAY,
    on: () => ({}),
  },
  [REPEATABLE_KEYS.WEEK]: {
    label: 'Week',
    labelPlural: 'Weeks',
    value: REPEATABLE_KEYS.WEEK,
    on: () => ({
      multiple: true,
      options: WEEK_DAYS,
    }),
  },
  [REPEATABLE_KEYS.MONTH]: {
    label: 'Month',
    labelPlural: 'Months',
    value: REPEATABLE_KEYS.MONTH,
    on: (service, startDate) => {
      return {
        multiple: false,
        options: [
          {
            value: `day.${startDate.getDate()}`,
            label: `day ${startDate.getDate()}`
          },
        ],
      };
    },
  }
};

const NO_SERVICES = [{ value: 'There are no services available', id: '', disabled: true }];

const clearTime = (date) => {
  const newDate = new Date(date.getTime());
  newDate.setHours(0);
  newDate.setMinutes(0);
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

function useAddInvoiceItem({ sc, instance, open, handleConfirm, handleUpdate, handleDelete }) {
  const checkinDate = new Date(sc.salesCaseCheckin);
  const checkoutDate = new Date(sc.salesCaseCheckout);

  const [changeRemainingItems, setChangeRemainingItems] = useState(false);

  const [service, setService] = useState(null);
  const [contractContact, setContractContact] = useState(
    sc.contract
      ? sc.contract.contact
      : sc.contractContact || sc.guest
  );

  const [startDate, setStartDate] = useState(checkinDate);
  const [endDate, setEndDate] = useState(checkoutDate);
  // Used for rendering the datepicker for the MONTHLY services
  // as the endDate for monthly services is the first day of next month
  // but the date picker should not show such date
  const [uiEndDate, setUiEndDate] = useState(checkoutDate);
  const [betweenFrom, setBetweenFrom] = useState('09:00');
  const [betweenTo, setBetweenTo] = useState('17:00');

  const [repeatable, setRepeatable] = useState(false);
  const [repeatableEvery, setRepeatableEvery] = useState('1');
  const [repeatablePeriod, setRepeatablePeriod] = useState(REPEATABLE_OPTIONS.day.value);
  const [repeatableOn, setRepeatableOn] = useState([]);

  const [price, setPrice] = useState('0');
  const [discount, setDiscount] = useState('0');
  const [discountMU, setDiscountMU] = useState('PERCENTAGE');
  const [description, setDescription] = useState('');

  const [servicesOptions, setServicesOptions] = useState([]);
  const [contactsOptions, setContacts] = useState([]);
  const [repeatableOptions, setRepeatableOptions] = useState([]);
  const [repeatableOnOptions, setRepeatableOnOptions] = useState([]);

  const [contactsFilter, setContactsFilter] = useState('');
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  const [finalServicePrice, setFinalServicePrice] = useState(0);

  const calculatePrice = (forAllRemaningItems = false) => {
    // If there is no instance or service the price is 0
    if (!instance && (!service || !service.price)) {
      return 0;
    }

    if (!instance && service && service.price) {
      return service.price;
    }

    const { checkin, checkout } = getCheckInAndCheckOut(sc);
    const totalSalesCasesNights = nightsBetween(checkin, checkout);
    const invoiceItemFrom = new Date(instance.from);
    const invoiceItemTo = new Date(instance.to);

    if (!forAllRemaningItems) {
      return instance.price;
    }
    
    if (instance.isRentalService) {
      const totalNights = totalSalesCasesNights < 28
        ? nightsBetween(invoiceItemFrom, invoiceItemTo)
        : 30;
      
      return Math.round(instance.basePrice * totalNights * 100) / 100;
    }
    
    return instance.basePrice;
  };

  const calculateAndSetPrice = (forAllRemaningItems = false) => {
    let price = calculatePrice(forAllRemaningItems);
    setPrice(`${price}`);
  };

  useEffect(() => {
    if (!contactsFilter) {
      setContacts([]);
      return;
    }

    let cancelled = false;

    setAutocompleteLoading(true);
    filterContacts(contactsFilter)
      .then(contactsOptions => !cancelled && setContacts(contactsOptions))
      .catch(() => !cancelled && setContacts(contactsOptions))
      .finally(() => setAutocompleteLoading(false));

    return () => {
      cancelled = true;
    };
  }, [contactsFilter, setContacts]);

  useEffect(() => {
    if (!instance || !open) {
      return;
    }

    setService(
      servicesOptions.find(
        service => service.id === instance.serviceId
      ),
    );

    // Set the contract contact based on the instance.contactId
    getContactById(instance.contractContactId)
      .then(setContractContact);

    // Set the dates based on the instance
    setStartDate(new Date(instance.from));
    setEndDate(new Date(instance.to));
    setBetweenFrom(instance.betweenFrom);
    setBetweenTo(instance.betweenTo);

    // Set the description based on the instance
    setDescription(instance.description || '');

    // Set the price attributes based on the instance
    calculateAndSetPrice();
    setDiscount(instance.discount);
    setDiscountMU(instance.discountMU || 'PERCENTAGE');
  }, [instance]);

  useEffect(() => {
    if (!instance) {
      return;
    }
    calculateAndSetPrice(changeRemainingItems);
  }, [changeRemainingItems]);

  useEffect(() => {
    getServicesForApartmentCategory(sc.apartments[0].catId)
      .then(setServicesOptions);
  }, [setServicesOptions, sc]);

  useEffect(() => {
    const finalPrice = parseFloat(price || '0');
    const finalDiscount = discountMU === '€'
      ? parseFloat(discount || '0')
      : parseFloat(price || '0') * parseFloat(discount || '0') / 100;
    const finalBasePrice = Math.max(
      0,
      finalPrice - finalDiscount,
    );
    setFinalServicePrice(isNaN(finalBasePrice) ? 0 : finalBasePrice);
  }, [price, discount, discountMU]);

  useEffect(() => {
    if (instance) {
      return;
    }
    if (!service) {
      setRepeatableOptions([]);
      return;
    }

    const repeatableOptions = Object.values(REPEATABLE_OPTIONS);
    setRepeatableOptions(repeatableOptions);
    if (repeatableOptions.length > 0) {
      setRepeatableEvery(1);
      setRepeatablePeriod(repeatableOptions[0].value);
    }
  }, [service, setRepeatableOptions]);

  useEffect(() => {
    if (instance) {
      return;
    }
    const repeatableOnOptions = REPEATABLE_OPTIONS[repeatablePeriod].on(
      service,
      startDate,
    );
    setRepeatableOnOptions(repeatableOnOptions);
    if (repeatablePeriod === REPEATABLE_KEYS.WEEK) {
      setRepeatableOn([`${startDate.getDay()}`]);
    } else if (repeatablePeriod === REPEATABLE_KEYS.MONTH && service && service.billingFrequency === 'ONE_TIME') {
      setRepeatableOn([repeatableOnOptions.options[0].value]);
    } else {
      setRepeatableOn([]);
    }
  }, [service, startDate, repeatablePeriod]);

  useEffect(() => {
    if (instance) {
      return;
    }
    calculateAndSetPrice();
    setRepeatableOn([]);
  }, [service]);

  const contactsIncludingSelected = [...contactsOptions];
  if (
    contractContact &&
    contractContact.id &&
    !contactsIncludingSelected.find(contact => contact.id === contractContact.id)
  ) {
    contactsIncludingSelected.push(contractContact);
  }

  const onServiceIdChange = ev => {
    const service = servicesOptions.find(s => s.id === ev.target.value);
    setService(service);
    setPrice(service.price || '0');
  };

  const onContractContactChange = (ev, contact) => {
    if (!contact) {
      return setContractContact({});
    }
    setContractContact(contact);
  };

  const toggleRepeatableOn = value => {
    let tmp = [...repeatableOn];
    const index = tmp.indexOf(value);
    if (index !== -1) {
      tmp.splice(index, 1);
    } else {
      tmp.push(value);
    }
    setRepeatableOn(tmp);
  };

  const getStartDate = () => {
    if (repeatable) {
      return startDate;
    }
    return clearTime(startDate);
  };

  const getEndDate = () => {
    if (repeatable) {
      return endDate;
    }
    if (service.billingFrequency === 'ONE_TIME') {
      return getStartDate();
    }
    return new Date(
      Math.min(
        endDate.getTime(),
        new Date(sc.salesCaseCheckout).getTime(),
      )
    );
  };

  const convertRepeatableOn = () => {
    if (service.billingFrequency === 'MONTHLY' || repeatablePeriod === REPEATABLE_KEYS.DAY) {
      return undefined;
    }
    if (repeatablePeriod === REPEATABLE_KEYS.MONTH) {
      return repeatableOn[0];
    }
    return `days.[${repeatableOn.sort().join(',')}]`;
  };

  const onSaveClicked = () => {
    const json = {
      service,
      contractContact,
      betweenFrom,
      betweenTo,
      repeatable,
      repeatablePeriod,
      discountMU,
      description,
      startDate: getStartDate(),
      endDate: getEndDate(),
      repeatableEvery: parseFloat(repeatableEvery),
      repeatableOn: convertRepeatableOn(),
      price: parseFloat(price),
      discount: parseFloat(discount),
    };
    handleConfirm(json);
  };

  const onUpdateClicked = () => {
    const json = {
      invoiceItem: instance,
      newData: {
        service,
        betweenFrom,
        betweenTo,
        discountMU,
        description,
        startDate: changeRemainingItems ? instance.from : getStartDate(),
        endDate: changeRemainingItems ? instance.to : getEndDate(),
        price: parseFloat(price),
        discount: parseFloat(discount),
      },
      changeRemainingItems,
    };
    handleUpdate(json);
  };

  const onDeleteClicked = () => {
    const json = {
      invoiceItem: instance,
      changeRemainingItems,
    };
    handleDelete(json);
  };

  const isFormReady = service && service.id && contractContact && contractContact.id && startDate;

  return {
    // Non state values
    minDate: checkinDate,
    maxDate: checkoutDate,

    // State related values
    changeRemainingItems,
    service,
    contractContact,
    startDate,
    betweenFrom,
    betweenTo,
    endDate,
    uiEndDate,
    repeatable,
    repeatableEvery,
    repeatablePeriod,
    repeatableOn,
    price,
    discount,
    discountMU,
    description,

    // Selects options and aux values
    servicesOptions: servicesOptions.length > 0 ? servicesOptions : NO_SERVICES,
    contactsOptions: contactsIncludingSelected,
    repeatableOptions,
    repeatableOnOptions,
    contactsFilter,
    autocompleteLoading,

    // Aux informative values
    finalServicePrice,
    isFormReady,

    // State setters
    setChangeRemainingItems,
    setService,
    setContractContact,
    setStartDate,
    setBetweenFrom,
    setBetweenTo,
    setEndDate,
    setUiEndDate,
    setRepeatable,
    setRepeatableEvery,
    setRepeatablePeriod,
    setRepeatableOn,
    setPrice,
    setDiscount,
    setDiscountMU,
    setDescription,
    setContactsFilter,

    // Event handlers
    onServiceIdChange,
    onContractContactChange,
    toggleRepeatableOn,
    onSaveClicked,
    onUpdateClicked,
    onDeleteClicked,
  };
}

export default useAddInvoiceItem;
