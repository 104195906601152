import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

const RightDrawer = styled(SwipeableDrawer)`
  z-index: 1201 !important;
  
  .MuiPaper-root {
    width: ${props => props.theme.layout.editDrawerWidth}px;
    display: flex;
    flex-direction: column;
  }
`;

const DrawerContent = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
`;

const DrawerActions = styled('div')`
  padding: 1rem;
`;

const DrawerSection = styled('div')`
  padding: 16px;
  
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const DrawerSectionHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HousekeepingSection = styled('div')`
  border-top: 1px solid rgba(224, 224, 224, 1);
`;

const HousekeepingSectionView = styled('div')`
  position: relative;
  padding: 1rem;
  border-top: 1px solid rgba(224, 224, 224, 1);

  &:hover {
    background: #f0f0f0;
    cursor: pointer;
  } 
`;

const HousekeepingSectionViewActions = styled('div')`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  display: flex;
  column-gap: 1rem;
`;

const HousekeepingItemsContainer = styled('div')`
  * {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
`;

export {
  DrawerActions,
  DrawerContent,
  DrawerSection,
  DrawerSectionHeader,
  RightDrawer,
  HousekeepingSection,
  HousekeepingSectionView,
  HousekeepingSectionViewActions,
  HousekeepingItemsContainer,
};
