export const PRICE_ACTIONS = {
  SET_PRICE_TO: 'SET_PRICE_TO',
  INCREASE_PRICE_BY: 'INCREASE_PRICE_BY',
  DECREASE_PRICE_BY: 'DECREASE_PRICE_BY',
};

export const PRICE_ACTION_LABELS = {
  SET_PRICE_TO: 'Set price to',
  INCREASE_PRICE_BY: 'Increase price by',
  DECREASE_PRICE_BY: 'Decrease price by',
};

export const PRICE_ACTION_LIST = [
  {
    value: PRICE_ACTIONS.SET_PRICE_TO,
    label: PRICE_ACTION_LABELS[PRICE_ACTIONS.SET_PRICE_TO],
  },
  {
    value: PRICE_ACTIONS.INCREASE_PRICE_BY,
    label: PRICE_ACTION_LABELS[PRICE_ACTIONS.INCREASE_PRICE_BY],
  },
  {
    value: PRICE_ACTIONS.DECREASE_PRICE_BY,
    label: PRICE_ACTION_LABELS[PRICE_ACTIONS.DECREASE_PRICE_BY],
  },
];

export const getBasePriceActionLabel =
  action => PRICE_ACTION_LABELS[action];
