import { useEffect, useState } from 'react';
import useSearch from './use-search';

function useDatatable(
  loadInstancesServiceAction,
  filterInstancesAction = null,
  saveInstanceServiceAction = null,
  validateInstanceBeforeSave = null,
  deleteInstanceServiceAction = null,
) {
  const [ready, setReady] = useState(false);
  const [page, setPage] = useState(1);
  const [instancesPerPage, setInstancesPerPage] = useState(50);
  const [instancesList, setInstancesList] = useState([]);
  const [filteredInstances, setFilteredInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [selectedInstanceError, setSelectedInstanceError] = useState(null);

  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { filter, filterChange } = useSearch();

  const filterInstances = (instancesList, filter) => {
    if (!ready) {
      setFilteredInstances([]);
      return;
    }
    if (!filterInstancesAction) {
      setFilteredInstances(instancesList);
      return;
    }
    setFilteredInstances(filterInstancesAction(
      instancesList,
      (filter || "").trim(),
    ));
  };

  const loadInstances = () => {
    loadInstancesServiceAction((page - 1) * instancesPerPage, instancesPerPage)
      .then((result) => {
        setInstancesList(Array.isArray(result) ? result : result.data);
        setReady(true);
      });
  };

  const saveInstance = (instance) => {
    const error = validateInstanceBeforeSave
      ? validateInstanceBeforeSave(instance, instancesList)
      : null;
    if (error) {
      setSelectedInstanceError(error);
      return;
    }
    if (!saveInstanceServiceAction) {
      return;
    }
    saveInstanceServiceAction(instance)
      .then((result) => {
        if (result) {
          loadInstances();
          setShowEditForm(false);
        } else {
          setSelectedInstanceError('There was an error saving the instance');
          setSelectedInstance({});
        }
      });
  };

  const deleteInstance = (instance) => {
    setShowDeleteConfirm(false);
    if (!deleteInstanceServiceAction) {
      return;
    }
    deleteInstanceServiceAction(instance)
      .then((result) => {
        if (result) {
          setSelectedInstance({});
          loadInstances();
        } else {
          setSelectedInstanceError('There was an error deleting the instance');
          setSelectedInstance({});
        }
      });
  };

  // eslint-disable-next-line
  useEffect(loadInstances, []);

  useEffect(() => {
    filterInstances(instancesList, filter);
  }, [instancesList, filter]);

  const handleAddButtonClicked = () => {
    setSelectedInstance({});
    setSelectedInstanceError(null);
    setShowEditForm(true);
  };

  const handleEditButtonClicked = _instance => {
    setSelectedInstance(_instance);
    setSelectedInstanceError(null);
    setShowEditForm(true);
  };

  const handleDeleteButtonClicked = _instance => {
    setSelectedInstance(_instance);
    setShowDeleteConfirm(true);
  };

  const handleCancelDeleteButtonClicked = _instance => {
    setSelectedInstance({});
    setShowDeleteConfirm(false);
  };

  const handleCancelEditButtonClicked = _instance => {
    setSelectedInstance({});
    setShowEditForm(false);
  };

  return {
    ready,
    page,
    selectedInstance,
    selectedInstanceError,
    showEditForm,
    showDeleteConfirm,
    filter,
    instancesList: filteredInstances,

    // Functions
    setPage,
    filterChange,
    saveInstance,
    deleteInstance,
    reloadFilter: () => filterInstances(instancesList, filter),
    reloadInstances: loadInstances,

    handleAddButtonClicked,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
    handleDeleteButtonClicked,
    handleCancelDeleteButtonClicked,
  };
}

export default useDatatable;
