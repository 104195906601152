import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function ModifyInstance({ title, handleClose, handleConfirm, deleteInstanceMsg }) {
  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="delete instance"
    >
      <DialogTitle>Confirm deletion</DialogTitle>
      <DialogContent>
        <DialogContentText>
          The action is not reversible. Are you sure you want to delete {deleteInstanceMsg}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleConfirm} autoFocus>Delete</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModifyInstance;
