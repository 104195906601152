import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(
  theme => ({
    Link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    }
  })
);

export { useStyles };
