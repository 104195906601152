import { styled } from '@mui/material/styles';

const HousekeepingSectionBody = styled('div')`
  * {
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-size: 0.8rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }
`;

export default HousekeepingSectionBody;
