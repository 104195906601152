import { getSissiInvoicingUrl } from '../config/server';

export async function getInvoicingSystemConfig() {
  const url = `${getSissiInvoicingUrl()}/invoicing-system-config`;
  const res = await fetch(url, { credentials: 'include' });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}

async function postInvoiceSystemConfig(config, test = false) {
  const url = `${getSissiInvoicingUrl()}/invoicing-system-config`;
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      ...(test ? { 'x-service-method': 'testConfig' } : {}),
    },
    body: JSON.stringify(config),
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}

export async function testInvoiceSystemConfig(config) {
  return await postInvoiceSystemConfig(config, true);
}

export async function saveInvoiceSystemConfig(config) {
  return await postInvoiceSystemConfig(config);
}
