import { useContext } from 'react';
import { AppBar, Button, FormControl, IconButton, TextField, Toolbar, Typography, } from '@mui/material';
import { Check as CheckIcon, Close as CloseIcon, Error as ErrorIcon } from '@mui/icons-material';

import { DrawerActions, DrawerContent, BorderedDrawerSection, RightDrawer, Spacer } from 'styled/components';

import HousekeepingProtocolHeader from 'components/HousekeepingProtocolHeader';
import HousekeepingProtocolSection from 'components/HousekeepingProtocolSection';
import useHousekeepingProtocolForm from './useHousekeepingProtocolForm';

import { emptyFunction } from 'utils/empty-function';
import { parseCantBeDoneReason } from 'utils/housekeeping-errors';
import { AppContext } from 'state/app-context';
import { systemRoles } from '../../constants';

function HousekeepingProtocolForm({ protocol, report, handleClose, handleConfirm, handleDelete, hideActions = false }) {

  const { currentUser } = useContext(AppContext);

  const {
    // State variables
    issues,
    sectionValues,

    // State setters
    setIssues,

    // Other variables
    finished,
    isFormReady,

    // Functions
    toggleSectionValue,
    getState,
  } = useHousekeepingProtocolForm({ protocol, report });

  // Handles the "Save draft" and "Finish" buttons clicks
  const handleConfirmButtonClicked = (finished = false) => {
    const housekeepingData = getState(finished);
    handleConfirm(housekeepingData);
  };

  //
  const userCanDeleteProtocols = Boolean(currentUser && currentUser.roles.includes(systemRoles.ADMIN));

  return (
    <RightDrawer
      open={Boolean(protocol.HousekeepingService)}
      onOpen={emptyFunction}
      onClose={handleClose}
      aria-labelledby="title"
      anchor="right"
      maxWidth="housekeepingDrawerWidth"
    >
      <AppBar position="relative">
        <Toolbar>
          {protocol.danger && <ErrorIcon color="yellow" sx={{ marginRight: '8px' }} />}
          <Typography fontWeight="bold">Housekeeping protocol form</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        {protocol.id && (
          <>
            {!protocol.canBeDone && (
              <BorderedDrawerSection>
                <Typography textAlign="center" color="red">
                  {parseCantBeDoneReason(protocol.cantBeDoneReason)}
                </Typography>
              </BorderedDrawerSection>
            )}

            <BorderedDrawerSection>
              <HousekeepingProtocolHeader protocol={protocol} report={report} />
            </BorderedDrawerSection>

            <BorderedDrawerSection>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <TextField
                  multiline
                  value={issues}
                  variant="standard"
                  label="Issues found (one per line)"
                  onChange={ev => setIssues(ev.target.value)}
                  InputLabelProps={{ shrink: true }}
                  disabled={finished || !protocol.canBeDone}
                />
              </FormControl>
            </BorderedDrawerSection>

            {protocol.HousekeepingService.sections.map(housekeepingSection => (
              <BorderedDrawerSection key={housekeepingSection.id}>
                <HousekeepingProtocolSection
                  section={housekeepingSection}
                  sectionValues={sectionValues}
                  toggleSectionValue={toggleSectionValue}
                  disabled={finished || !protocol.canBeDone}
                />
              </BorderedDrawerSection>
            ))}
          </>
        )}
      </DrawerContent>

      {!hideActions && (
        <DrawerActions sx={{ display: 'flex', alignItems: 'center', columnGap: '3rem', justifyContent: 'center' }}>
          {userCanDeleteProtocols && (
            <Button onClick={() => handleDelete()} color="error">
              Delete protocol
            </Button>
          )}
          <Spacer />
          <Button
            disabled={!protocol.canBeDone}
            onClick={() => handleConfirmButtonClicked()}
          >
            Save Draft
          </Button>
          <Button
            variant="contained"
            disabled={!protocol.canBeDone || !isFormReady}
            sx={{ display: 'flex', alignItems: 'center', lineHeight: '1rem', columnGap: '0.25rem' }}
            onClick={() => handleConfirmButtonClicked(true)}
          >
            <CheckIcon sx={{ width: '1.2rem' }} />
            <span>Finish</span>
          </Button>
        </DrawerActions>
      )}
    </RightDrawer>
  );
}

export default HousekeepingProtocolForm;
