import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { HousekeepingItemsContainer, HousekeepingSectionView, HousekeepingSectionViewActions, } from './styled';

import './ckeditor.css';

function DisplayHousekeepingSection({ instance, handleEdit, handleDelete }) {

  const handleDeleteButtonClicked = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    handleDelete();
  };

  return (
    <HousekeepingSectionView onClick={handleEdit ? handleEdit : () => {
    }} tabIndex={-1}>
      <Typography>
        <Typography
          fontWeight="bold"
          component="span"
          fontSize="0.9rem"
        >
          {instance.title}
        </Typography>
        {instance.required ? ' (Required)' : ''}
      </Typography>
      <HousekeepingItemsContainer>
        {instance.type === 'SINGLE_ANSWER' && (
          <p dangerouslySetInnerHTML={{ __html: instance.content }}></p>
        )}
        {instance.type === 'MULTI_ANSWER' && (
          <ul>
            {instance.items.split(/\n/).map((item, index) => (
              <li key={`${item}-${index}`}>{item}</li>
            ))}
          </ul>
        )}
      </HousekeepingItemsContainer>
      <HousekeepingSectionViewActions>
        <IconButton onClick={handleDeleteButtonClicked}>
          <RemoveCircleIcon color="error" />
        </IconButton>
      </HousekeepingSectionViewActions>
    </HousekeepingSectionView>
  );
}

export default DisplayHousekeepingSection;
