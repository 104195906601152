import { styled } from '@mui/material/styles';

const AppContent = styled('div')`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.palette.background.default};
  align-items: center;
  justify-content: center;
`;

export { AppContent };
