import { useState } from 'react';
import { findInvoicingSystemDataWithCredentials, INVOICING_SYSTEM_MODELS } from '../../api/invoicing-system-data';
import { setupInvoicingSystemConfig } from '../../api/invoicing-system-config-setup';

const configSteps = [
  'Credentials',
  'Companies',
  'Services',
  'Confirm',
];

function useConfigWizard() {
  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [invoicingSystemConfig, setInvoicingSystemConfig] = useState(undefined);

  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState({});

  const [services, setServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});

  const isCurrentStepReady = (() => {
    if (currentStep === 0) {
      return Boolean(
        invoicingSystemConfig?.type
        && invoicingSystemConfig?.url
        && invoicingSystemConfig?.database
        && invoicingSystemConfig?.user
        && invoicingSystemConfig?.pass
      );
    }
    if (currentStep === 1) {
      return Object.keys(selectedCompanies).length > 0;
    }
    if (currentStep === 2) {
      return Object.keys(selectedServices).length > 0;
    }
    return true;
  })();

  /**
   * Validates the config and gets the list of existing companies
   * @returns {Promise<boolean>}
   */
  const validateConfigAndFetchCompanies = async () => {
    try {
      // Get the list of companies of the invoicing system
      // using the configuration specified.
      // If the config is invalid an error will be thrown.
      const res = await findInvoicingSystemDataWithCredentials(
        INVOICING_SYSTEM_MODELS.COMPANY,
        invoicingSystemConfig,
      );
      setCompanies(res.data);
      return true;
    } catch (err) {
      console.error(err);
      setErrors(err.errors);
    }
    return false;
  }

  /**
   * Get the list of services of the invoicing system
   * using the configuration specified.
   * @returns {Promise<boolean>}
   */
  const fetchServices = async () => {
    try {
      const res = await findInvoicingSystemDataWithCredentials(
        INVOICING_SYSTEM_MODELS.SERVICE,
        invoicingSystemConfig,
      );
      setServices(res.data);
      return true;
    } catch (err) {
      console.error(err);
      setErrors(err.errors);
    }
    return false;
  }

  const saveConfig = async () => {
    try {
      const companiesToPush = companies
        .filter(company => selectedCompanies[company.id])
        .map(company => ({
          name: company.name,
          invoicingSystemId: company.id,
        }));
      const servicesToPush = services
        .filter(service => selectedServices[service.id])
        .map(service => ({
          name: service.name,
          description: service.description || '',
          invoicingSystemId: service.id,
          isRentalService: false,
        }));
      await setupInvoicingSystemConfig(
        invoicingSystemConfig,
        companiesToPush,
        servicesToPush,
      );
      return true;
    } catch (err) {
      console.error('Error saving the config', err);
      setErrors(err.errors);
    }
    return false;
  }

  const goToPrevStep = async () => {
    setCurrentStep(Math.max(currentStep - 1, 0));
  }

  const goToNextStep = async () => {
    if (!isCurrentStepReady) {
      return;
    }
    setErrors({});
    setShowLoading(true);
    // Credentials step
    if (currentStep === 0) {
      const requestSucceeded = await validateConfigAndFetchCompanies();
      requestSucceeded && setCurrentStep(currentStep + 1);
    }
    // Companies step
    if (currentStep === 1) {
      const requestSucceeded = await fetchServices();
      requestSucceeded && setCurrentStep(currentStep + 1);
    }
    // Services step
    if (currentStep === 2) {
      // This should be replaced so nothing happens on this step
      // As it transitions to a step where all the configs are displayed
      const requestSucceeded = await saveConfig();
      requestSucceeded && setCurrentStep(currentStep + 1);
    }
    // // Confirm step
    // if (currentStep === 3) {
    //   const requestSucceeded = await saveConfig();
    //   requestSucceeded && setCurrentStep(currentStep + 1);
    // }
    setShowLoading(false);
  }

  return {
    configSteps,
    isCurrentStepReady,
    goToPrevStep,
    goToNextStep,
    currentStep,
    setCurrentStep,
    invoicingSystemConfig,
    setInvoicingSystemConfig,
    errors,
    setErrors,
    showLoading,
    setShowLoading,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    services,
    selectedServices,
    setSelectedServices,
  }
}

export default useConfigWizard;
