import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

function useSearch(defaultValue = undefined) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [filter, setFilter] = useState(defaultValue
    ? defaultValue
    : (searchParams.get('s') || '')
  );

  useEffect(() => {
    const urlFilter = searchParams.get('s');
    if (filter !== urlFilter) {
      setFilter(urlFilter || defaultValue);
    }
    // eslint-disable-next-line
  }, []);

  const filterChange = (ev) => {
    const filter = ev.target.value;

    // Update the search params
    const tmpSearchParams = Object.fromEntries(searchParams);
    if (filter.length > 0) {
      tmpSearchParams.s = filter;
    } else {
      delete tmpSearchParams.s;
    }
    setSearchParams(tmpSearchParams);

    // Update the filter in the state
    setFilter(filter);
  };

  return {
    filter,
    filterChange,
  };
}

export default useSearch;
