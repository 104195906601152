import { getSissiCoreUrl } from '../config/server';

export async function getCurrentUser() {
  const url = `${getSissiCoreUrl()}/session/authenticatedUser`;
  const res = await fetch(url, { credentials: 'include' });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}
