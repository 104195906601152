import { styled } from '@mui/material/styles';

const Actions = styled('div')`
  display: flex;
  align-items: center;
  
  & > *:not(:last-child) {
    margin-right: ${props => props.theme.spacing(3)};
  }
`;

const TableCounter = styled('p')`
  font-size: ${props => props.theme.spacing(1.5)};
`;

export { Actions, TableCounter };
