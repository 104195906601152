import { TextField } from '@mui/material';

function LoadingIndicator({ onChange, ...props }) {

  const handleOnChange = ev => {
    const newValue = ev.target.value.replace(/,/g, '.');
    const isValidNumber = !isNaN(parseFloat(newValue)) && isFinite(newValue);

    if (isValidNumber || newValue === '') {
      const newEvent = {
        ...ev,
        target: {
          ...ev.target,
          value: newValue
        }
      };

      // trigger the new event
      onChange(newEvent);
    }

    ev.preventDefault();
    ev.stopPropagation();
  };

  const handleOnBlur = ev => {
    if (!props.value || parseFloat(props.value) < 0) {
      const newEvent = {
        target: {
          value: '0'
        }
      };

      // trigger the new event
      onChange(newEvent);
    }
  };

  return (
    <TextField
      type="text"
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      {...props}
    />
  );
}

export default LoadingIndicator;
