import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import styled from '@mui/material/styles/styled';
import { CircularProgress, TableRow } from '@mui/material';

export const AppBar = styled(MuiAppBar)`
  zIndex: ${props => props.theme.zIndex.drawer + 1};
`;

export const AppContent = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${props => props.theme.spacing(2)};
  
  & > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(1.5)} !important;;
  }
`;

export const Page = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  
  & > *:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(1.5)} !important;;
  }
`;

export const Section = styled(Page)`
  padding: ${props => props.theme.spacing(2)};
`;

export const BlockContentRow = styled('div')`
  &:nth-of-type(1) {
    margin-bottom: ${props => props.theme.spacing(1.5)} !important;
    padding-bottom: ${props => props.theme.spacing(2)};
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  &:nth-of-type(2) {
    margin-bottom: ${props => props.theme.spacing(1.5)} !important;
  }
  
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

export const ContentRow = styled(BlockContentRow)`
  display: ${props => props.display || 'flex'};
  flex-direction: ${props => props.flexDirection || 'row'};
  align-items: center;
`;

export const ContentRowNoTable = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

export const Spacer = styled('div')`
  flex-grow: 1;
`;

export const RightDrawer = styled(SwipeableDrawer)`
  z-index: 1201 !important;
  
  .MuiPaper-root {
    width: 100%;
    max-width: ${props => props.maxWidth && props.theme.layout[props.maxWidth]
            ? props.theme.layout[props.maxWidth]
            : props.theme.layout['editDrawerWidth']}px;
    display: flex;
    flex-direction: column;
  }
`;

export const DrawerContent = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
`;

export const DrawerActions = styled('div')`
  padding: 1rem;
`;

export const DrawerSection = styled('div')`
  padding: ${props => props.theme.spacing(2)};

  & > *:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const BorderedDrawerSection = styled(DrawerSection)`
  &:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

export const DrawerSectionForm = styled('div')`
  margin-top: ${props => props.theme.spacing(1)};
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: ${props => props.theme.spacing(2)};
  }
`;

export const FormRow = styled('div')`
  padding: ${props => props.theme.spacing(2)};
`;

export const ButtonCircularProgress = styled(CircularProgress)`
  margin-right: ${props => props.theme.spacing(1)};

  & svg {
    color: white;
  }
`;

export const InteractiveRow = styled(TableRow)`
  cursor: pointer;
  &.selected, &:hover {
    background: ${props => props.theme.palette.grey['200']};
  }
  &.selected {
    & td {
      font-weight: bold;
    }
    & + tr {
      background: ${props => props.theme.palette.grey['100']};
    }
  }
`;
