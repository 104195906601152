import { Typography } from '@mui/material';

import HousekeepingProtocolSectionWrapper from './HousekeepingProtocolSectionWrapper';
import HousekeepingProtocolSingleAnswerSectionBody from './HousekeepingProtocolSingleAnswerSectionBody';
import HousekeepingProtocolMultiAnswerSectionBody from './HousekeepingProtocolMultiAnswerSectionBody';

/**
 * Housekeeping section
 * @param section
 * @param sectionValues
 * @param toggleSectionValue
 * @param [disabled]
 * @returns {JSX.Element}
 * @constructor
 */
function HousekeepingProtocolSection({ section, sectionValues, toggleSectionValue, disabled = false }) {
  return (
    <div>
      <Typography fontSize="0.9rem" fontWeight="bold">
        {section.title}
        {section.required ? ' (Required)' : ''}
      </Typography>
      <HousekeepingProtocolSectionWrapper>
        {section.type === 'SINGLE_ANSWER' && (
          <HousekeepingProtocolSingleAnswerSectionBody
            section={section}
            disabled={disabled}
            sectionValues={sectionValues}
            toggleSectionValue={toggleSectionValue}
          />
        )}
        {section.type === 'MULTI_ANSWER' && (
          <HousekeepingProtocolMultiAnswerSectionBody
            section={section}
            disabled={disabled}
            sectionValues={sectionValues}
            toggleSectionValue={toggleSectionValue}
          />
        )}
      </HousekeepingProtocolSectionWrapper>
    </div>
  );
}

export default HousekeepingProtocolSection;
