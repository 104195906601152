import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';

import LoadingIndicator from 'components/loading-indicator';
import TableHeader from 'components/table-header';
import { AppContent, ContentRow } from 'styled/components';
import BulkEditServices from './bulk-edit-services';

import useDatatable from 'hooks/use-datatable';
import { getServices, saveServices } from 'api/service';

function Services() {
  const filterInstances = (instancesList, filter) => instancesList.filter(
    _instance => {
      return _instance.name.toLowerCase().includes(filter.toLowerCase());
    }
  );

  const {
    ready,
    page,
    instancesList,
    filter,

    // Functions
    setPage,
    filterChange,
    reloadFilter,
    reloadInstances,
  } = useDatatable(getServices, filterInstances);

  const [selected, setSelected] = useState({});
  const [showEditServices, setShowEditServices] = useState(false);

  useEffect(() => {
    const selectedTmp = { ...selected };
    instancesList.forEach(apartmentCategory => {
      if (selectedTmp[apartmentCategory.id]) {
        selectedTmp[apartmentCategory.id] = apartmentCategory;
      }
    });
    setSelected(selectedTmp);
  }, [instancesList]);

  if (!ready) {
    return <LoadingIndicator />;
  }

  const rangeStart = (page - 1) * 50;
  const rangeEnd = page * 50 - 1;
  const pageFilteredInstances = instancesList.filter((_instance, index) => {
    return index >= rangeStart && index <= rangeEnd;
  });

  const numberOfPages = Math.ceil(instancesList.length / 50);
  const pages = [];
  for (let i = 1; i <= numberOfPages; i++) {
    pages.push(i);
  }

  const toggleSelectAll = () => {
    const result = {};
    if (Object.keys(selected).length !== pageFilteredInstances.length) {
      pageFilteredInstances.forEach(instance => {
        result[instance.id] = instance;
      });
    }
    setSelected(result);
  };

  const toggleSelectInstance = instance => {
    const result = { ...selected };
    if (result[instance.id]) {
      delete result[instance.id];
    } else {
      result[instance.id] = instance;
    }
    setSelected(result);
  };

  const selectAndEditPrices = (ev, instance) => {
    ev.preventDefault();
    ev.stopPropagation();
    setSelected({
      [instance.id]: instance,
    });
    setShowEditServices(true);
  };

  const handleEditPrices = (services) => {
    saveServices(services)
      .then(() => {
        reloadInstances();
        setShowEditServices(false);
      });
  };

  const selectedCount = Object.keys(selected).length;

  return (
    <>
      <AppContent>
        <TableHeader
          title="Services"
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={instancesList.length}
        >
          {selectedCount > 0 && (
            <Button
              variant="contained"
              onClick={() => setShowEditServices(!showEditServices)}
              size="small"
            >
              Edit {selectedCount} selected
            </Button>
          )}
        </TableHeader>
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 0 }}>
                    <Checkbox
                      checked={Object.keys(selected).length === pageFilteredInstances.length}
                      onChange={toggleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell sx={{textAlign: 'center'}}>Journal</TableCell>
                  <TableCell sx={{textAlign: 'center'}}>Type</TableCell>
                  <TableCell sx={{ width: 30 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageFilteredInstances.length > 0 && pageFilteredInstances.map((_instance) => {
                  let type = 'Regular';
                  if(_instance.isRentalService) {
                    type = 'Rental'
                  }
                  if(_instance.isServiceFee) {
                    type = 'Service Fee'
                  }
                  if(_instance.isFinalCleaningService) {
                    type = 'Final Cleaning'
                  }
                  return (
                    <TableRow
                      key={_instance.id}
                      sx={{
                        'td': { paddingTop: 1, paddingBottom: 1 },
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      onClick={() => toggleSelectInstance(_instance)}
                    >
                      <TableCell>
                        <Checkbox
                          checked={selected.hasOwnProperty(_instance.id)}
                        />
                      </TableCell>
                      <TableCell>{_instance.name}</TableCell>
                      <TableCell sx={{textAlign: 'center'}}>
                        {_instance.invoicingSystemJournalId || '-'}
                      </TableCell>
                      <TableCell sx={{textAlign: 'center'}}>
                        {type}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(ev) => selectAndEditPrices(ev, _instance)}
                        >
                          <ChevronRight />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {pageFilteredInstances.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan="5">
                      <Typography>There are no Services yet.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
        <ContentRow style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
          {pages.map(_page => (
            <Button
              key={`page-${_page}`}
              size="small"
              variant="contained"
              color={_page === page ? 'primary' : 'neutral'}
              sx={{ minWidth: 'auto' }}
              onClick={() => setPage(_page)}
            >
              {_page}
            </Button>
          ))}
        </ContentRow>
      </AppContent>
      <BulkEditServices
        handleClose={() => setShowEditServices(false)}
        handleConfirm={handleEditPrices}
        error={undefined}
        selectedInstances={Object.values(selected)}
        open={showEditServices}
      />
    </>
  );
}

export default Services;
