import { getSissiInvoicingUrl } from '../config/server';

export async function getServices() {
  const url = `${getSissiInvoicingUrl()}/services?$limit=1000`;
  const res = await fetch(url, {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}

export async function saveService(data) {
  const url = `${getSissiInvoicingUrl()}/services`;
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}

export async function saveServices(data) {
  const url = `${getSissiInvoicingUrl()}/services`;
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-service-method': 'bulkUpdate',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}
