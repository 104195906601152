import { getSissiInvoicingUrl } from '../config/server';

export async function getCompanies() {
  const url = `${getSissiInvoicingUrl()}/companies`;
  const res = await fetch(url, { credentials: 'include' });
  if (res.status !== 200) {
    throw res.json();
  }
  return await res.json();
}

export async function saveCompany(data) {
  const url = `${getSissiInvoicingUrl()}/companies`;
  const res = await fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}
