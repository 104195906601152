import { Alert, Button, Step, StepLabel, Stepper, Toolbar } from '@mui/material';

import { AppBar, ButtonCircularProgress, FormRow } from '../../styled/components';
import InvoicingSystemConfig from '../../components/invoicing-system-config';
import {
  StepperWrapper,
  Wizard,
  WizardActionsWrapper,
  WizardStepWrapper,
  WizardWrapper
} from './config-wizard.styled.ts';
import useConfigWizard from './config-wizard.hook';
import { errorDomains, getErrorByDomain } from '../../i18n';
import MultiSelectionList from '../../components/multi-selection-list';
import InvoicingSystemConfigSuccessSetup from '../../components/invoicing-system-config-success-setup';
import Typography from '@mui/material/Typography';

function ConfigWizard() {
  const {
    configSteps,
    companies,
    selectedCompanies,
    setSelectedCompanies,
    services,
    selectedServices,
    setSelectedServices,
    isCurrentStepReady,
    goToPrevStep,
    goToNextStep,
    currentStep,
    invoicingSystemConfig,
    setInvoicingSystemConfig,
    showLoading,
    errors,
  } = useConfigWizard();
  const { code: errorCode } = errors || {};

  return (
    <WizardWrapper>
      <Wizard>
        <AppBar position="relative">
          <Toolbar sx={{ justifyContent: 'center' }}>
            <Typography variant="h6">Sissi - Invoicing System Config</Typography>
          </Toolbar>
        </AppBar>

        <StepperWrapper>
          <Stepper activeStep={currentStep} alternativeLabel>
            {configSteps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </StepperWrapper>

        <WizardStepWrapper>
          {errorCode && (
            <FormRow>
              <Alert severity="error" icon={false}>
                {getErrorByDomain(errorDomains.INVOICING_SYSTEM_CONFIG, errorCode)}
              </Alert>
            </FormRow>
          )}
          {currentStep === 0 && (
            <InvoicingSystemConfig
              editMode
              errors={errors}
              initialConfig={invoicingSystemConfig}
              onChange={setInvoicingSystemConfig}
            />
          )}
          {currentStep === 1 && (
            <FormRow>
              <MultiSelectionList
                options={companies}
                initialValue={selectedCompanies}
                onChange={setSelectedCompanies}
              />
            </FormRow>
          )}
          {currentStep === 2 && (
            <FormRow>
              <MultiSelectionList
                options={services}
                initialValue={selectedServices}
                onChange={setSelectedServices}
              />
            </FormRow>
          )}
          {currentStep === 3 && (
            <InvoicingSystemConfigSuccessSetup />
          )}
        </WizardStepWrapper>

        {currentStep < 3 && (
          <WizardActionsWrapper>
            <Button
              variant="outlined"
              disabled={currentStep === 0}
              onClick={goToPrevStep}
            >
              Prev
            </Button>
            <Button
              variant="contained"
              disabled={!isCurrentStepReady || showLoading}
              onClick={goToNextStep}
            >
              {showLoading && <ButtonCircularProgress size={12} />}
              <span>{currentStep === configSteps.length - 1 ? 'Finish' : 'Next'}</span>
            </Button>
          </WizardActionsWrapper>
        )}
      </Wizard>
    </WizardWrapper>
  );
}

export default ConfigWizard;
