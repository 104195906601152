import { Card, IconButton, Table, TableBody, TableCell, TableHead, TableRow, } from '@mui/material';
import { ChevronRight as ChevronRightIcon, } from '@mui/icons-material';
import { formatDate, } from './api-services';

function Invoice({ invoice, selectInvoiceItemForEdit }) {

  return (
    <Card>
      <Table sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
        <TableHead>
          <TableRow
            sx={{ 'th': { paddingTop: 1.25, paddingBottom: 1.25 } }}
          >
            <TableCell>Service</TableCell>
            <TableCell>Start date</TableCell>
            <TableCell>End date</TableCell>
            <TableCell>Gross unit price</TableCell>
            <TableCell>Discount</TableCell>
            <TableCell>Discounted gross unit price</TableCell>
            <TableCell sx={{ width: 30 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoice.items.map((invoiceItem) => {
            const discount = invoiceItem.discountMU === 'PERCENTAGE' ? invoiceItem.discount * invoiceItem.price / 100 : invoiceItem.discount;
            return (
              <TableRow
                key={`${invoiceItem.id}-${invoiceItem.from}`}
                sx={{ 'td': { paddingTop: 0.25, paddingBottom: 0.25 } }}
              >
                <TableCell>{invoiceItem.productName}</TableCell>
                <TableCell>{formatDate(invoiceItem.from)}</TableCell>
                <TableCell>{formatDate(invoiceItem.to)}</TableCell>
                <TableCell>€ {invoiceItem.price.toFixed(2)}</TableCell>
                <TableCell>€ {discount.toFixed(2)}</TableCell>
                <TableCell>€ {(invoiceItem.price - discount).toFixed(2)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={ev => selectInvoiceItemForEdit(invoiceItem)}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Card>
  );
}

export default Invoice;
