import { getSissiInvoicingUrl } from "config/server";

export async function getHousekeepingServices() {
  const url = `${getSissiInvoicingUrl()}/housekeeping-services?$limit=1000`;
  const res = await fetch(url, {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return (await res.json()).data;
}

export async function saveHousekeepingServices(housekeepingService) {
  const url = `${getSissiInvoicingUrl()}/housekeeping-services${housekeepingService.id ? `/${housekeepingService.id}` : ''}`;
  const res = await fetch(url, {
    method: housekeepingService.id ? 'PUT' : 'POST',
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...housekeepingService,
    }),
  });
  if (![200, 201].includes(res.status)) {
    throw await res.json();
  }
  return await res.json();
}

export async function deleteHousekeepingServices(instance) {
  return new Promise(resolve => {
    resolve(true);
  });
}
