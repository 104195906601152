import { createTheme } from '@mui/material';
import createCache from '@emotion/cache';

export const muiCache = createCache({
  'key': 'mui',
  'prepend': true,
});

const theme = createTheme({
  palette: {
    primary: {
      light: '#52c7b8',
      main: '#009688',
      dark: '#00675b',
    },
    text: {
      link: 'blue',
    },
    neutral: {
      main: '#ffffff',
      contrastText: '#000000',
      dark: '#ffffff'
    },
    yellow: {
      main: '#F8D233',
    }
  },
  layout: {
    drawerWidth: 320,
    editDrawerWidth: 400,
    housekeepingDrawerWidth: 600,
  },
  typography: {
    fontSize: 12,
  },
});

export default theme;
