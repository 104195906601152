import { useEffect, useState } from 'react';

function useHousekeepingProtocolForm({ protocol, report }) {
  const [issues, setIssues] = useState('');
  const [sectionValues, setSectionValues] = useState({});

  useEffect(() => {
    setIssues(report && report.issues ? report.issues : '');
    setSectionValues(report && report.sectionValues ? report.sectionValues : {});
  }, [protocol, report]);

  const toggleSectionValue = (key) => {
    setSectionValues({
      ...sectionValues,
      [key]: !Boolean(sectionValues[key]),
    });
  };

  const getState = (finished) => {
    return {
      ...(protocol && report && report.id ? { id: report.id } : {}),
      issues,
      finished,
      sectionValues,
      ProtocolId: protocol.id,
    };
  };

  const finished = Boolean(report && report.completedAt);

  const isFormReady = protocol && protocol.HousekeepingService && protocol.HousekeepingService.sections
    .filter(section => section.required)
    .reduce((result, section) => {
      const tmp = [...result];
      if (section.type === 'SINGLE_ANSWER') {
        tmp.push(`${section.id}-0`);
      }
      if (section.type === 'MULTI_ANSWER') {
        section.items.split(/\n/).forEach((_, index) => {
          tmp.push(`${section.id}-${index + 1}`);
        });
      }
      return tmp;
    }, [])
    .every(housekeepingSectionKey => sectionValues[housekeepingSectionKey]);

  return {
    // State variables
    issues,
    sectionValues,

    // Stete setters
    setIssues,
    setSectionValues,

    // Other variables
    finished,
    isFormReady,

    // Functions
    toggleSectionValue,
    getState,
  };
}

export default useHousekeepingProtocolForm;
