import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { FormRow } from '../../styled/components';
import { errorDomains, getErrorByDomain } from '../../i18n';
import useInvoicingSystemConfig from './invoicing-system-config.hook';

const configFields = {
  TYPE: 'type',
  URL: 'url',
  DATABASE: 'database',
  USER: 'user',
  PASSWORD: 'pass',
};

function InvoicingSystemConfig({ errors = {}, editMode, initialValue, title, hideTitle, onChange }) {
  const {
    typeInputProps,
    urlInputProps,
    databaseInputProps,
    userInputProps,
    passInputProps,
  } = useInvoicingSystemConfig({ initialValue, onChange });

  return (
    <form>
      <FormRow>
        <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
          <InputLabel>Invoicing system type (*)</InputLabel>
          <Select
            error={errors[configFields.TYPE]}
            name={configFields.TYPE}
            disabled={!editMode}
            {...typeInputProps}
          >
            {['ODOO'].map(type => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
          {errors[configFields.TYPE] && (
            <FormHelperText>
              {getErrorByDomain(errorDomains.VALIDATION, errors[configFields.TYPE])}
            </FormHelperText>
          )}
        </FormControl>
      </FormRow>
      <FormRow>
        <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
          <TextField
            error={errors[configFields.URL]}
            helperText={errors[configFields.URL]
              ? getErrorByDomain(errorDomains.VALIDATION, errors[configFields.URL])
              : undefined}
            name={configFields.URL}
            label="Server url (*)"
            type="url"
            variant="standard"
            disabled={!editMode}
            {...urlInputProps}
          />
        </FormControl>
      </FormRow>
      <FormRow>
        <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
          <TextField
            error={errors[configFields.DATABASE]}
            helperText={errors[configFields.DATABASE]
              ? getErrorByDomain(errorDomains.VALIDATION, errors[configFields.DATABASE])
              : undefined}
            name={configFields.DATABASE}
            label="Database (*)"
            variant="standard"
            disabled={!editMode}
            {...databaseInputProps}
          />
        </FormControl>
      </FormRow>
      <FormRow>
        <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
          <TextField
            error={errors[configFields.USER]}
            helperText={errors[configFields.USER]
              ? getErrorByDomain(errorDomains.VALIDATION, errors[configFields.USER])
              : undefined}
            name={configFields.USER}
            label="User (*)"
            variant="standard"
            disabled={!editMode}
            {...userInputProps}
          />
        </FormControl>
      </FormRow>
      {editMode && (
        <FormRow>
          <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
            <TextField
              error={errors[configFields.PASSWORD]}
              helperText={errors[configFields.PASSWORD]
                ? getErrorByDomain(errorDomains.VALIDATION, errors[configFields.PASSWORD])
                : undefined}
              name={configFields.PASSWORD}
              label="Password (*)"
              variant="standard"
              {...passInputProps}
              type="password"
            />
          </FormControl>
        </FormRow>
      )}
    </form>
  );
}

export default InvoicingSystemConfig;
