import Alert from '@mui/material/Alert';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { FormControlLabel } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';

import { SameRowFields, SameRowFlexFields } from './styled';
import {
  BorderedDrawerSection,
  DrawerActions,
  DrawerContent,
  DrawerSection,
  RightDrawer,
  Spacer,
} from 'styled/components';
import useAddInvoiceItem from './use-add-invoice-item';
import InputAdornment from '@mui/material/InputAdornment';
import NumberInput from 'components/NumberInput';

const datePickerViewMonthly = ['year', 'month'];
const datePickerViewOneTime = ['year', 'month', 'day'];

const timePickerValues = [];
const dateHours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const dateMinutes = ['00', '10', '20', '30', '40', '50'];
dateHours.forEach(hour => {
  dateMinutes.forEach(minutes => {
    timePickerValues.push(`${hour}:${minutes}`);
  });
});

function AddInvoiceItem({
  open,
  sc,
  instance,
  editable,
  handleClose,
  handleConfirm,
  handleUpdate,
  handleDelete,
  error,
}) {
  const {
    // Non state values
    minDate,
    maxDate,

    // State related values
    changeRemainingItems,
    service,
    contractContact,
    startDate,
    betweenFrom,
    betweenTo,
    uiEndDate,
    repeatable,
    repeatableEvery,
    repeatablePeriod,
    repeatableOn,
    price,
    discount,
    discountMU,
    description,

    // Selects options and aux values
    servicesOptions,
    contactsOptions,
    repeatableOptions,
    repeatableOnOptions,
    autocompleteLoading,

    // Aux informative values
    finalServicePrice,
    isFormReady,

    // State setters
    setChangeRemainingItems,
    setStartDate,
    setBetweenFrom,
    setBetweenTo,
    setEndDate,
    setUiEndDate,
    setRepeatable,
    setRepeatableEvery,
    setRepeatablePeriod,
    setRepeatableOn,
    setPrice,
    setDiscount,
    setDiscountMU,
    setDescription,
    setContactsFilter,

    // Event handlers
    onServiceIdChange,
    onContractContactChange,
    toggleRepeatableOn,
    onSaveClicked,
    onUpdateClicked,
    onDeleteClicked,
  } = useAddInvoiceItem({ sc, instance, open, handleConfirm, handleUpdate, handleDelete });

  if (!servicesOptions) {
    return null;
  }

  return (
    <RightDrawer
      open={open}
      onOpen={() => {
      }}
      onClose={handleClose}
      aria-labelledby="Add Invoice Item"
      anchor="right"
    >
      {open && (
        <>
          <AppBar position="relative">
            <Toolbar>
              <Typography fontWeight="bold">
                {`${!instance ? 'Add' : 'Modify'} Invoice Item`}
              </Typography>
              <Spacer />
              <IconButton onClick={handleClose}>
                <CloseIcon sx={{ color: '#fff' }} />
              </IconButton>
            </Toolbar>
          </AppBar>
          <DrawerContent>
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>{error}</Alert>
            )}

            {instance && (
              <DrawerSection>
                <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        checked={changeRemainingItems}
                        onChange={() => setChangeRemainingItems(!changeRemainingItems)}
                        inputProps={{ 'aria-label': 'Repeatable' }}
                        sx={{ margin: 0 }}
                        disabled={!editable}
                      />
                    }
                    label="Apply to all remaining items"
                  />
                </FormControl>
              </DrawerSection>
            )}

            <DrawerSection>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <InputLabel>Service</InputLabel>
                <Select
                  value={service ? `${service.id}` : ''}
                  onChange={onServiceIdChange}
                  disabled={Boolean(instance)}
                >
                  {servicesOptions.filter(service => (!instance && (!service || !service.isRentalService)) || instance).map(service => (
                    <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DrawerSection>

            <DrawerSection>
              <Autocomplete
                disabled={Boolean(instance)}
                value={contractContact}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name || ''}
                options={contactsOptions}
                loading={autocompleteLoading}
                onChange={onContractContactChange}
                filterOptions={(x) => x}
                onInputChange={ev => ev && ev.target && setContactsFilter(ev.target.value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Invoice contact"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {autocompleteLoading ?
                            <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    sx={{ margin: 0 }}
                  />
                )}
              />
            </DrawerSection>

            {!instance && (
              <DrawerSection>
                <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                  <FormControlLabel
                    control={
                      <Switch
                        disabled={!service || !editable}
                        checked={repeatable}
                        onChange={() => setRepeatable(!repeatable)}
                        inputProps={{ 'aria-label': 'Repeatable' }}
                        sx={{ margin: 0 }}
                      />
                    }
                    label="This item is recurrent"
                  />
                </FormControl>
              </DrawerSection>
            )}

            <DrawerSection>
              <SameRowFlexFields>
                <Typography component="label" sx={{ width: '60px', minWidth: '60px'}}>
                  {!repeatable ? 'On' : 'From'}:
                </Typography>
                <DatePicker
                  disabled={Boolean(changeRemainingItems || (instance && service && service.isRentalService) || !editable)}
                  sx={{ width: '100%', margin: 0 }}
                  views={datePickerViewOneTime}
                  minDate={minDate}
                  maxDate={uiEndDate}
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      autoComplete="false"
                      sx={{ width: '100%', margin: 0 }}
                    />
                  )}
                  inputFormat={'dd/MM/yyyy'}
                />
              </SameRowFlexFields>
            </DrawerSection>

            {repeatable && service && (
              <DrawerSection>
                <SameRowFlexFields>
                  <Typography component="label" sx={{ width: '60px', minWidth: '60px'}}>
                    Until:
                  </Typography>
                  <DatePicker
                    sx={{ width: '100%', margin: 0 }}
                    views={datePickerViewOneTime}
                    minDate={startDate}
                    maxDate={maxDate}
                    value={uiEndDate}
                    onChange={setEndDate}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        autoComplete="false"
                        sx={{ width: '100%', margin: 0 }}
                      />
                    )}
                    inputFormat={service && 'dd/MM/yyyy'}
                    disabled={!editable}
                  />
                </SameRowFlexFields>
              </DrawerSection>
            )}

            {repeatable && service && (
              <DrawerSection>
                <SameRowFlexFields>
                  <Typography component="label" sx={{ width: '60px', minWidth: '60px'}}>
                    Every:
                  </Typography>
                  <SameRowFields>
                    <TextField
                      name="repeat-every"
                      type="number"
                      variant="standard"
                      value={repeatableEvery}
                      InputProps={{ inputProps: { min: 1 } }}
                      onChange={ev => setRepeatableEvery(ev.target.value)}
                      onBlur={() => ((!repeatableEvery.length || parseFloat(repeatableEvery) < 0) && setRepeatableEvery(1))}
                      sx={{ margin: 0, width: '100%', minWidth: '100px' }}
                      disabled={!editable}
                    />
                    <Select
                      variant="standard"
                      value={repeatablePeriod}
                      onChange={ev => setRepeatablePeriod(ev.target.value)}
                      sx={{ margin: 0, width: '100%', minWidth: '100px' }}
                      disabled={!editable}
                    >
                      {repeatableOptions.map(repeatableOption => (
                        <MenuItem
                          key={repeatableOption.value}
                          value={repeatableOption.value}
                        >
                          {repeatableEvery > 1 ? repeatableOption.labelPlural : repeatableOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </SameRowFields>
                </SameRowFlexFields>
              </DrawerSection>
            )}

            {repeatable && service && repeatableOnOptions.options && (
              <DrawerSection>
                <SameRowFlexFields>
                  <Typography component="label" sx={{ width: '60px', minWidth: '60px'}}>
                    On
                  </Typography>
                  {!repeatableOnOptions.multiple ? (
                    <Select
                      variant="standard"
                      value={repeatableOn.length > 0 ? repeatableOn[0] : '-'}
                      onChange={ev => setRepeatableOn([ev.target.value])}
                      sx={{ margin: 0, width: '100%' }}
                      disabled={!editable}
                    >
                      {repeatableOn.length === 0 && (
                        <MenuItem value="-">Select ...</MenuItem>
                      )}
                      {repeatableOnOptions.options.map(repeatableOnOption => (
                        <MenuItem
                          key={repeatableOnOption.value}
                          value={repeatableOnOption.value}
                        >
                          {repeatableOnOption.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
                      {repeatableOnOptions.options.map(repeatableOnOption => (
                        <Button
                          size="small"
                          key={repeatableOnOption.value}
                          onClick={() => toggleRepeatableOn(repeatableOnOption.value)}
                          variant={repeatableOn.includes(repeatableOnOption.value) ? 'contained' : 'outlined'}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '10px',
                            borderRadius: '50%',
                            minWidth: '30px',
                            width: '30px',
                            height: '30px',
                            lineHeight: '0.75rem'
                          }}
                        >
                          {repeatableOnOption.label}
                        </Button>
                      ))}
                    </div>
                  )}
                </SameRowFlexFields>
              </DrawerSection>
            )}

            {service && (
              <DrawerSection>
                <SameRowFlexFields>
                  <Typography component="label" sx={{ width: '60px', minWidth: '60px'}}>
                    Between
                  </Typography>
                  <SameRowFlexFields style={{ flexGrow: 1 }}>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option === value}
                      defaultValue={betweenFrom}
                      getOptionLabel={option => option}
                      options={timePickerValues}
                      onChange={(_, value) => setBetweenFrom(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{ margin: 0 }}
                        />
                      )}
                      sx={{ flexGrow: 1 }}
                      disableClearable
                      disabled={!editable}
                    />
                    <span> - </span>
                    <Autocomplete
                      isOptionEqualToValue={(option, value) => option === value}
                      getOptionDisabled={option => option < betweenFrom}
                      defaultValue={betweenTo}
                      getOptionLabel={option => option}
                      options={timePickerValues}
                      onChange={(_, value) => setBetweenTo(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{ margin: 0 }}
                        />
                      )}
                      sx={{ flexGrow: 1 }}
                      disableClearable
                      disabled={!editable}
                    />
                  </SameRowFlexFields>
                </SameRowFlexFields>
              </DrawerSection>
            )}

            <DrawerSection>
              <TextField
                multiline
                variant="standard"
                label="Description"
                value={description}
                onChange={ev => setDescription(ev.target.value)}
                sx={{ margin: 0, width: '100%' }}
              />
            </DrawerSection>

            <BorderedDrawerSection>
              <SameRowFields>
                <FormControl sx={{ margin: 0 }} variant="standard">
                  <NumberInput
                    variant="standard"
                    label="Gross Unit Price"
                    value={price}
                    onChange={ev => setPrice(ev.target.value)}
                    disabled={!service || !editable}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">€</InputAdornment>,
                    }}
                  />
                </FormControl>
                <SameRowFields>
                  <FormControl sx={{ margin: 0 }} variant="standard">
                    <NumberInput
                      variant="standard"
                      label="Discount"
                      value={discount}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={ev => setDiscount(ev.target.value)}
                      disabled={!service || !editable}
                    />
                  </FormControl>
                  <FormControl sx={{ margin: 0 }} variant="standard">
                    <InputLabel>MU</InputLabel>
                    <Select
                      value={discountMU}
                      onChange={ev => setDiscountMU(ev.target.value)}
                      disabled={!service || !editable}
                    >
                      <MenuItem value="PERCENTAGE">%</MenuItem>
                      <MenuItem value="EURO">€</MenuItem>
                    </Select>
                  </FormControl>
                </SameRowFields>
              </SameRowFields>
            </BorderedDrawerSection>

            <DrawerSection>
              <Typography
                textAlign="right"
                fontWeight="bold"
              >
                € {(Math.round(finalServicePrice * 100) / 100).toFixed(2)}
              </Typography>
            </DrawerSection>
          </DrawerContent>
          <DrawerActions style={{ rowGap: '1rem', display: 'flex', flexDirection: 'column' }}>
            {instance && (!service || !service.isRentalService) && editable && (
              <Button
                color="warning"
                onClick={onDeleteClicked}
                variant="contained"
                sx={{ width: '100%' }}
                disabled={!isFormReady}
              >
                <DeleteIcon /> Delete
              </Button>
            )}
            <Button
              onClick={instance && instance.id ? onUpdateClicked : onSaveClicked}
              variant="contained"
              sx={{ width: '100%' }}
              disabled={!isFormReady}
            >
              <CheckIcon /> Save
            </Button>
          </DrawerActions>
        </>
      )}
    </RightDrawer>
  );
}

export default AddInvoiceItem;
