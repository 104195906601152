import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { useStyles } from './styled';

function LinkMenuItem({ icon, title, onClick, to, active, ...other }) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const handleClickMenuItem = (ev) => {
    navigate(to);
    handleClick(ev);
  };

  const handleLinkClick = (ev) => {
    ev.stopPropagation();
    handleClick(ev);
  };

  const handleClick = (ev) => {
    if (onClick) {
      onClick(ev);
    }
  };

  return (
    <MenuItem
      onClick={handleClickMenuItem}
      {...other}
    >
      {icon && (
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText>
        <Link
          to={to}
          onClick={handleLinkClick}
          className={clsx([classes.Link, active ? 'active-menu-item' : null])}
        >
          {title}
        </Link>
      </ListItemText>
    </MenuItem>
  );
}

export default LinkMenuItem;
