import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { defaultAppContextValue, AppContext } from './state/app-context';

import PrivateContent from './pages/private-content';
import ConfigurationWizard from "./pages/config-wizard";

// Admin pages
import FeatureFlags from './pages/feature-flags';
import Pricing from './pages/pricing';
import Services from './pages/services';
import Housekeeping from './pages/housekeeping-admin';
import HousekeepingReason from './pages/housekeeping-reason';

// Accounting pages
import RentalDeposits from './pages/rental-deposits';
import InvoicePlan from './pages/invoice-plan';

// Housekeeping pages
import HousekeepingProtocols from './pages/HousekeepingProtocols';
import HousekeepingReports from './pages/housekeeping-reports';

import Unauthorized from './components/unauthorized';
import LoadingIndicator from './components/loading-indicator';

import { loadInitialData } from './api';
import InvoicePlansList from './pages/invoice-plans-list';
import InvoicingSystem from './pages/invoicing-system';
import { systemRoles } from './constants';

function App() {
  const [contextData, setAppContextData] = useState(defaultAppContextValue);
  const { ready, currentUser, isAppConfigured } = contextData;

  useEffect(() => {
    loadInitialData().then(({ invoicingSystemConfig, currentUser, companies }) => {
      // Set the current user in the windows object for its usage in the fetch client
      window.currentUser = currentUser;

      // Set the invoicing system config
      if (invoicingSystemConfig) {
        window.invoicingSystemConfig = {
          url: invoicingSystemConfig.url,
          db: invoicingSystemConfig.database,
          username: invoicingSystemConfig.user,
          password: invoicingSystemConfig.pass,
        };
      }

      // Set the current company in the windows object for its usage in the fetch client
      if (companies && companies.length > 0) {
        let currentCompany;
        let currentCompanyId = window.localStorage.getItem('currentCompanyId');
        // Check if the there is a currentCompanyId and get the company with its id
        if (currentCompanyId) {
          currentCompany = companies.find(company => company.id === currentCompanyId);
        }
        // If currentCompany is not yet defined set the first company in the list
        if (!currentCompany) {
          currentCompany = companies[0];
        }
        // Set the currentCompanyId in the local storage for future page refreshes
        window.localStorage.setItem('currentCompanyId', currentCompany.id);
        // Set the current companyId in the windows object for its usage in the fetch client
        window.currentCompanyId = currentCompany.id;
        window.currentCompany = currentCompany;
      }

      setAppContextData({
        ready: true,
        currentUser,
        companies,
        isAppConfigured: !!invoicingSystemConfig,
      });
    });
  }, []);

  if (!ready) {
    return <LoadingIndicator />;
  }

  if (!currentUser) {
    return <Unauthorized />;
  }

  if (!isAppConfigured) {
    return <ConfigurationWizard />;
  }

  let defaultRoute = null;
  if (currentUser.roles.includes(systemRoles.ADMIN)) {
    defaultRoute = '/admin/services';
  } else if (currentUser.roles.includes(systemRoles.SALES)) {
    defaultRoute = '/accounting/invoice-plans';
  } else if (currentUser.roles.includes(systemRoles.HOUSEKEEPING)) {
    defaultRoute = '/housekeeping/pending';
  }

  return (
    <AppContext.Provider value={contextData}>
      <Routes>
        <Route element={<PrivateContent />}>
          <Route path="/" element={defaultRoute ? <Navigate to={defaultRoute} /> : null} />

          {currentUser.roles.includes(systemRoles.ADMIN) && (
            [
              <Route key="/admin" path="/admin" element={<Navigate to="/admin/services" />} />,
              <Route key="/admin/invoicing-system" path="/admin/invoicing-system" element={<InvoicingSystem />} />,
              <Route key="/admin/feature-flags" path="/admin/feature-flags" element={<FeatureFlags />} />,
              <Route key="/admin/services" path="/admin/services" element={<Services />} />,
              <Route key="/admin/pricing" path="/admin/pricing" element={<Pricing />} />,
              <Route key="/admin/housekeeping-services" path="/admin/housekeeping-services" element={<Housekeeping />} />,
              <Route key="/admin/housekeeping-reasons" path="/admin/housekeeping-reasons" element={<HousekeepingReason />} />,
            ]
          )}

          {currentUser.roles.includes(systemRoles.SALES) && (
            [
              <Route key="/accounting" path="/accounting" element={<Navigate to="/accounting/invoice-plans" />} />,
              <Route key="/accounting/invoice-plans" path="/accounting/invoice-plans" element={<InvoicePlansList />} />,
              <Route key="/accounting/invoice-plans/:salesCaseId" path="/accounting/invoice-plans/:salesCaseId" element={<InvoicePlan />} />,
              <Route key="/accounting/rental-deposits" path="/accounting/rental-deposits" element={<RentalDeposits />} />,
            ]
          )}

          {currentUser.roles.includes(systemRoles.HOUSEKEEPING) && (
            [
              <Route key="/housekeeping" path="/housekeeping" element={<Navigate to="/housekeeping/protocols" />} />,
              <Route key="/housekeeping/protocols" path="/housekeeping/protocols" element={<HousekeepingProtocols />} />,
              <Route key="/housekeeping/reports" path="/housekeeping/reports" element={<HousekeepingReports />} />,
            ]
          )}

          <Route path="*" element={<h1>Not found</h1>} />
        </Route>
      </Routes>
    </AppContext.Provider>
  );
}

export default App;
