import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { getServices } from 'api/service';

import { Spacer } from 'styled/components';
import { DrawerActions, DrawerContent, DrawerSection, DrawerSectionHeader, RightDrawer, } from './styled';

import ModifyHousekeepingSection from './modify-section';
import DisplayHousekeepingSection from './display-section';

function ModifyInstance({ instance, handleClose, handleConfirm, error, open }) {
  const [ready, setReady] = useState(false);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedServices, setSelectedServices] = useState([]);
  const [runsAfterCheckout, setRunsAfterCheckout] = useState(false);
  const [triggersScStatusChange, setTriggersScStatusChange] = useState(false);
  const [sections, setSections] = useState([]);

  const [selectedSection, setSelectedSection] = useState(undefined);
  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    getServices()
      .then(servicesRes => {
        setServiceOptions(servicesRes.data);
        setReady(true);
      })
      .catch((err) => {
        handleClose();
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!instance) {
      return;
    }
    setName(instance.name || '');
    setDescription(instance.description || '');
    setSelectedServices(instance.Services ? instance.Services.map(s => s.id) : []);
    setRunsAfterCheckout(Boolean(instance.runsAfterCheckout));
    setTriggersScStatusChange(Boolean(instance.triggersScStatusChange));
    setSections(instance.sections || []);
  }, [instance]);

  const handleDeleteSectionByIndex = (index) => {
    const tmp = [...sections];
    tmp.splice(index, 1);
    setSections(tmp);
  };

  const handleSaveSection = (section) => {
    const tmp = [...sections];
    if (!section.id) {
      section.id = new Date().getTime();
      tmp.push(section);
    } else {
      const index = sections.findIndex(s => s.id === section.id);
      tmp[index] = section;
    }
    setSections(tmp);
    setSelectedSection(null);
  };

  const handleConfirmButtonClicked = () => {
    handleConfirm({
      ...(instance.id ? { id: instance.id } : {}),
      name,
      description,
      sections,
      runsAfterCheckout,
      triggersScStatusChange,
      serviceIds: selectedServices,
    });
  };

  const title = `${instance.id ? 'Edit' : 'Add'} Housekeeping service`;

  return (
    <RightDrawer
      open={open}
      onOpen={() => {
      }}
      onClose={handleClose}
      aria-labelledby={title}
      anchor="right"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">{title}</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        {ready ? (
          <>
            <DrawerSection>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <TextField
                  label="Name"
                  value={name}
                  variant="standard"
                  onChange={(ev) => setName(ev.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <TextField
                  multiline
                  variant="standard"
                  value={description}
                  label="Description"
                  onChange={(ev) => setDescription(ev.target.value)}
                />
              </FormControl>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <InputLabel>Service</InputLabel>
                <Select
                  value={selectedServices}
                  onChange={ev => setSelectedServices(ev.target.value)}
                  multiple
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => {
                        const service = serviceOptions.find(s => s.id === value);
                        return (
                          <Chip key={value} label={service ? service.name : 'Unknown'} />
                        );
                      })}
                    </Box>
                  )}
                >
                  {serviceOptions.map(service => (
                    <MenuItem key={service.id} value={service.id}>{service.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <FormControlLabel
                  control={
                    <Switch
                      checked={runsAfterCheckout}
                      onChange={() => setRunsAfterCheckout(!runsAfterCheckout)}
                      inputProps={{ 'aria-label': 'Runs after checkout' }}
                      sx={{ margin: 0 }}
                    />
                  }
                  label="Runs after checkout"
                />
              </FormControl>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <FormControlLabel
                  control={
                    <Switch
                      checked={triggersScStatusChange}
                      onChange={() => setTriggersScStatusChange(!triggersScStatusChange)}
                      inputProps={{ 'aria-label': 'Runs after checkout' }}
                      sx={{ margin: 0 }}
                    />
                  }
                  label="Triggers SC status change"
                />
              </FormControl>
            </DrawerSection>
            <DrawerSection>
              <DrawerSectionHeader>
                <Typography>Sections</Typography>
                {!selectedSection && (
                  <Button onClick={() => setSelectedSection({})}>Add new</Button>
                )}
              </DrawerSectionHeader>
              <div>
                {sections.map((section, index) => {
                  return selectedSection && selectedSection.id === section.id
                    ? (
                      <ModifyHousekeepingSection
                        instance={selectedSection}
                        handleConfirm={handleSaveSection}
                        handleClose={() => setSelectedSection(undefined)}
                      />
                    )
                    : (
                      <DisplayHousekeepingSection
                        key={`section-${index}`}
                        instance={section}
                        handleEdit={() => setSelectedSection(section)}
                        handleDelete={() => handleDeleteSectionByIndex(index)}
                      />
                    );
                })}
                {selectedSection && !selectedSection.id && (
                  <ModifyHousekeepingSection
                    instance={selectedSection}
                    handleConfirm={handleSaveSection}
                    handleClose={() => setSelectedSection(undefined)}
                  />
                )}
              </div>
            </DrawerSection>
          </>
        ) : null}
      </DrawerContent>
      {!selectedSection && (
        <DrawerActions>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              marginTop: '1rem',
              display: 'flex',
              alignItems: 'center',
              lineHeight: '1rem',
              columnGap: '0.25rem'
            }}
            onClick={handleConfirmButtonClicked}
          >
            <CheckIcon sx={{ width: '1.2rem' }} />
            <span>Save</span>
          </Button>
        </DrawerActions>
      )}
    </RightDrawer>
  );
}

export default ModifyInstance;
