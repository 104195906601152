import { AppBar, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Outlet } from 'react-router-dom';

import CurrentCompanySelector from '../../components/current-company-selector';
import NavItems from '../../components/nav-items';
import { Spacer } from '../../styled/components';

import { AppLayout, AppMain, LeftDrawer, LeftDrawerCompanySelector, useStyles } from './private-content.styled';
import useDrawerState from './use-drawer-state';
import useWindowsSize from '../../hooks/use-windows-size';

function PrivateContent() {
  const { drawerOpened, setDrawerOpened, handleItemClicked } = useDrawerState();
  const windowsSize = useWindowsSize();
  const { classes } = useStyles();

  return (
    <AppLayout>
      <AppBar className={classes.AppBar}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1 }}
            onClick={() => setDrawerOpened(!drawerOpened)}
          >
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src="/logo.png" alt="logo" />
          </Link>
          <Spacer />
          {windowsSize.width > 600 && (
            <CurrentCompanySelector size="small" sx={{
              color: 'white',
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(228, 219, 233, 0.25)',
              },
              '.MuiSvgIcon-root ': {
                fill: 'white !important',
              }
            }} />
          )}
        </Toolbar>
      </AppBar>

      <LeftDrawer
        variant="persistent"
        anchor="left"
        open={drawerOpened}
        className={drawerOpened ? '' : 'drawerClosed'}
      >
        {windowsSize.width <= 600 && (
          <LeftDrawerCompanySelector>
            <CurrentCompanySelector size="small" />
          </LeftDrawerCompanySelector>
        )}
        <NavItems onItemClick={handleItemClicked} />
      </LeftDrawer>

      <AppMain>
        <Outlet />
      </AppMain>
    </AppLayout>
  );
}

export default PrivateContent;
