import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const CollapsibleMenuWrapper = styled('div')`
  > .MuiMenuItem-root {
    padding-left: 24px;
  }

  .MuiMenuItem-root + .MuiCollapse-root .MuiMenuItem-root {
    padding-left: 60px;
  }
`;

export { CollapsibleMenuWrapper, ExpandMore };
