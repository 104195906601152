import { getSissiCoreUrl } from '../../config/server';

const NON_REACTIVABLE_STATUSES = ['ACTIVE', 'EXPIRED'];

function getDeposits() {
  const url = `${getSissiCoreUrl()}/deposit?sort=id&order=asc`;
  return fetch(url, { credentials: 'include' })
    .then(res => res.json())
    .then(deposits => {
      return deposits.map(deposit => ({
        ...deposit,
        canBeReactivated: !NON_REACTIVABLE_STATUSES.includes(
          getDepositStatus(deposit)
        ),
        paymentIntents: deposit.paymentIntents.sort((a, b) => {
          if (a.dateCreated > b.dateCreated) {
            return -1;
          }
          if (a.dateCreated < b.dateCreated) {
            return 1;
          }
          return 0;
        }),
      }));
    })
    .catch(() => Promise.resolve([]));
}

function reactivateDeposit(deposit) {
  const url = `${getSissiCoreUrl()}/stripe/updateDepositPayment/${deposit.id}`;
  return fetch(url, { method: 'POST', credentials: 'include' })
    .then(res => res)
    .catch(() => Promise.resolve(undefined));
}

function getDepositStatus(deposit) {
  if (deposit.invalid) {
    return 'CARD ERROR';
  }
  if (deposit.expired) {
    return 'CLOSED';
  }
  return deposit.paymentIntents.some(pi => pi.status === 'ACTIVE')
    ? 'ACTIVE'
    : 'PENDING REACTIVATION';
}

export { getDeposits, getDepositStatus, reactivateDeposit };
