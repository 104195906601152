import useInputState from '../../hooks/use-input-state';
import { useEffect } from 'react';

const defaultConfig = {
  type: 'ODOO',
  url: 'https://qsviennaresidence.wt-io-it.at',
  database: 'qsmdesign',
  user: 'development@viennaresidence.com',
  pass: 'vr#bro73000',
};

function useInvoicingSystemConfig({ initialValue = defaultConfig, onChange }) {
  const [type, typeInputProps, setType] = useInputState(initialValue.type);
  const [url, urlInputProps, setUrl] = useInputState(initialValue.url);
  const [database, databaseInputProps, setDatabase] = useInputState(initialValue.database);
  const [user, userInputProps, setUser] = useInputState(initialValue.user);
  const [pass, passInputProps, setPass] = useInputState(initialValue.pass);

  useEffect(() => {
    onChange && onChange({ type, url, database, user, pass });
  }, [type, url, database, user, pass, onChange]);

  return {
    type,
    typeInputProps,
    setType,
    url,
    urlInputProps,
    setUrl,
    database,
    databaseInputProps,
    setDatabase,
    user,
    userInputProps,
    setUser,
    pass,
    passInputProps,
    setPass,
  };
}

export default useInvoicingSystemConfig;
