import { getSissiInvoicingUrl } from "config/server";

export async function getHousekeepingReports(params = {}) {
  const filter = params.filter || undefined;
  const $skip = params.skip || 0;
  const $limit = params.limit || 50;

  const urlSearchParams = new URLSearchParams({
    '$skip': $skip,
    '$limit': $limit,
    ...(filter ? { "searchable[$like]": `%${filter}%` } : {})
  });

  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/reports`);
  url.search = urlSearchParams.toString();

  const res = await fetch(decodeURIComponent(url.toString()), {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return (await res.json());
}

export async function saveHousekeepingReport(data = {}) {
  let url = new URL(`${getSissiInvoicingUrl()}/housekeeping/reports`);
  if (data.id) {
    url = `${url}/${data.id}`;
  }

  const res = await fetch(url.toString(), {
    method: data.id ? 'PUT' : 'POST',
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
    body: JSON.stringify({
      issuerId: window.currentUser.id,
      ...data,
    }),
  });
  if (res.status !== 201 && res.status !== 200) {
    throw await res.json();
  }
  return (await res.json());
}
