import { useState } from 'react';

import useBEDatatable from '../../hooks/use-be-datatable';

function useHousekeepingProtocols(
  loadInstancesServiceAction,
  saveInstanceServiceAction = () => {},
  validateInstanceBeforeSave = () => {},
  deleteInstanceServiceAction = () => {},
) {
  const [addExtraItem, setAddExtraItem] = useState(false);
  const datatableData = useBEDatatable({
    loadInstancesServiceAction,
    saveInstanceServiceAction,
    validateInstanceBeforeSave,
    deleteInstanceServiceAction,
  });

  return {
    ...datatableData,
    addExtraItem,
    setAddExtraItem,
  };
}

export default useHousekeepingProtocols;
