import CircularProgress from '@mui/material/CircularProgress';

import { LoadingIndicatorWrapper } from './styled';

function LoadingIndicator() {
  return (
    <LoadingIndicatorWrapper>
      <CircularProgress color="inherit" />
    </LoadingIndicatorWrapper>
  );
}

export default LoadingIndicator;
