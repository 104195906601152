import {
  Alert,
  AppBar,
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { DrawerActions, DrawerContent, DrawerSection, DrawerSectionForm, RightDrawer, TableInput } from './styled';
import { PRICE_ACTIONS, PRICE_ACTION_LIST } from './constants';
import { useEffect, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import PriceInput from './price-input';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Spacer } from 'styled/components';
import { getServices } from 'api/service';

const convertServicesPrices = (apartmentCategories) => {
  // If there are more than one category selected
  // or there are no service prices for the apartment category
  // then do not set the price otherwise
  if (
    apartmentCategories.length > 1 ||
    !apartmentCategories[0].prices.servicesPrices ||
    apartmentCategories[0].prices.servicesPrices.length === 0
  ) {
    return {};
  }
  return apartmentCategories[0].prices.servicesPrices.reduce(
    (servicesPricesMap, servicePrice) => ({
      ...servicesPricesMap,
      [servicePrice.serviceId]: servicePrice,
    }),
    {},
  );
};

function BulkEditPrices({ selectedInstances, handleClose, handleConfirm, error, open }) {
  const _instances = Object.values(selectedInstances);
  const isSingleInstance = _instances.length === 1;

  const [ready, setReady] = useState(false);

  const [basePriceAction, setBasePriceAction] = useState('');
  const [basePrice, setBasePrice] = useState('');
  const [basePriceMU, setBasePriceMU] = useState('EURO');
  const [rentalService, setRentalService] = useState(null);

  const [stayLengthDiscounts, setStayLengthDiscounts] = useState([]);
  const [lastMinuteDiscounts, setLastMinuteDiscounts] = useState([]);

  const [rentals, setRentals] = useState([]);
  const [services, setServices] = useState([]);
  const [servicesPrices, setServicesPrices] = useState({});

  useEffect(() => {
    if (!open) {
      return cleanup();
    }
    getServices()
      .then(init)
      .catch(console.error);
  }, [open]);

  const init = (servicesResponse) => {
    const services = servicesResponse.data.filter(service => (
      service.invoicingSystemJournalId && service.billingFrequency
    ));
    if (selectedInstances.length === 1) {
      const instancePrices = selectedInstances[0].prices;
      setBasePrice(instancePrices.basePrice || 0);
      setRentalService(instancePrices.RentalService || null);
      setLastMinuteDiscounts(instancePrices.lastMinuteDiscounts || []);
      setStayLengthDiscounts(instancePrices.stayLengthDiscounts || []);
      setServicesPrices(convertServicesPrices(selectedInstances));
    } else {
      setBasePrice('');
      setLastMinuteDiscounts([]);
      setStayLengthDiscounts([]);
      setServicesPrices({});
    }
    setBasePriceAction(PRICE_ACTIONS.SET_PRICE_TO);
    setRentals(services.filter(service => service.isRentalService));
    setServices(services.filter(service => !service.isRentalService));
    setReady(true);
  };

  const cleanup = () => {
    setReady(false);
    setBasePriceAction('');
    setBasePrice(0);
    setBasePriceMU('EURO');
    setRentalService(null);
    setServicesPrices({});
    setLastMinuteDiscounts([]);
    setStayLengthDiscounts([]);
    setServices([]);
  };

  const addStayLengthDiscount = () => {
    setStayLengthDiscounts([...stayLengthDiscounts, { from: '0', to: '0', value: '0', mu: 'PERCENTAGE' }]);
  };

  const deleteStayLengthDiscount = (index) => {
    const tmp = [...stayLengthDiscounts];
    tmp.splice(index, 1);
    setStayLengthDiscounts(tmp);
  };

  const updateStayLengthDiscountField = (ev, index) => {
    const tmp = [...stayLengthDiscounts];
    tmp[index][ev.target.name] = ev.target.value;
    setStayLengthDiscounts(tmp);
  };

  const addLastMinuteDiscount = () => {
    setLastMinuteDiscounts([...lastMinuteDiscounts, { from: '0', to: '0', value: '0', mu: 'PERCENTAGE' }]);
  };

  const deleteLastMinuteDiscount = (index) => {
    const tmp = [...lastMinuteDiscounts];
    tmp.splice(index, 1);
    setLastMinuteDiscounts(tmp);
  };

  const updateLastMinuteDiscountsField = (ev, index) => {
    const tmp = [...lastMinuteDiscounts];
    tmp[index][ev.target.name] = ev.target.value;
    setLastMinuteDiscounts(tmp);
  };

  const setSingleServicePrice = (service, price) => {
    setServicesPrices({
      ...servicesPrices,
      [service.id]: {
        serviceId: service.id,
        value: price
      },
    });
  };

  const validateSingleServicePrice = (service) => {
    if (servicesPrices[service.id] && !isNaN(servicesPrices[service.id].value)) {
      return;
    }
    if (selectedInstances.length === 1) {
      return setSingleServicePrice(service, '0');
    }
    const tmpPrices = {...servicesPrices };
    delete tmpPrices[service.id];
    setServicesPrices(tmpPrices);
  };

  const handlerentalServiceChange = ev => {
    const rentalService = rentals.find(service => service.id === ev.target.value);
    setRentalService(rentalService);
  };

  const handleInputFocus = ev => {
    ev.target.select();
  };

  const handleConfirmButtonClicked = () => {
    let pricing = {
      priceAction: basePriceAction,
      basePrice: basePrice === '' ? null : parseFloat(`${basePrice}`),
      basePriceMU,
      stayLengthDiscounts: stayLengthDiscounts.map(discount => ({
        ...discount,
        from: parseInt(discount.from),
        to: parseInt(discount.to),
        value: parseInt(discount.value),
      })),
      lastMinuteDiscounts: lastMinuteDiscounts.map(discount => ({
        ...discount,
        from: parseInt(discount.from),
        to: parseInt(discount.to),
        value: parseInt(discount.value),
      })),
      RentalServiceId: rentalService ? rentalService.id : undefined,
      servicesPrices: Object.values(servicesPrices).map(sericePrice => ({
        ...sericePrice,
        value: parseInt(sericePrice.value),
      })),
    };

    // Cleanup the pricing data
    if (selectedInstances.length > 1) {
      if (!rentalService) {
        delete pricing.rentalService;
      }
      if (basePrice <= 0) {
        delete pricing.basePrice;
      }
      if (stayLengthDiscounts.length === 0) {
        delete pricing.stayLengthDiscounts;
      }
      if (lastMinuteDiscounts.length === 0) {
        delete pricing.lastMinuteDiscounts;
      }
      if (pricing.servicesPrices.length === 0) {
        delete pricing.servicesPrices;
      }

      // Close the form if there is no change
      if (Object.keys(pricing).length === 0) {
        handleClose();
      }
    }

    handleConfirm(pricing);
  };

  const selectedTitle = `${_instances.length} listing${!isSingleInstance ? 's' : ''} selected`;

  return (
    <RightDrawer
      open={open}
      onOpen={() => {}}
      onClose={handleClose}
      aria-labelledby="Edit Prices for service(s)"
      anchor="right"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">Bulk edit ({selectedTitle})</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        <DrawerSection>
          <Alert severity="warning" sx={{ maxWidth: 'calc(100% - 2rem)', flexDirection: 'row !important', alignItems: 'center' }}>
            <Typography variant="body2">Updating the prices for more than one Apartment Category at the same time is dangerous.</Typography>
            <Typography>Please check every field before clicking <b>APPLY</b>.</Typography>
          </Alert>
        </DrawerSection>
        {ready ? (
          <>
            <DrawerSection>
              <Typography>BASE RATE:</Typography>
              <DrawerSectionForm>
                <Select
                  value={basePriceAction}
                  size="small"
                  onChange={ev => setBasePriceAction(ev.target.value)}
                  sx={{ flexGrow: 1 }}
                >
                  {PRICE_ACTION_LIST.map(action => (
                    <MenuItem key={action.value} value={action.value}>{action.label}</MenuItem>
                  ))}
                </Select>
                <TableInput
                  size="small"
                  onFocus={handleInputFocus}
                  value={basePrice || '0'}
                  sx={{ maxWidth: 100, '& input': { textAlign: 'right' } }}
                  onChange={ev => setBasePrice(ev.target.value)}
                  onBlur={ev => basePrice === '' && setBasePrice('')}
                />
                <Select
                  variant="outlined"
                  size="small"
                  name="mu"
                  value={basePriceMU}
                  onChange={ev => setBasePriceMU(ev.target.value)}
                >
                  <MenuItem value="PERCENTAGE">%</MenuItem>
                  <MenuItem value="EURO">€</MenuItem>
                </Select>
              </DrawerSectionForm>
              <FormControl sx={{ width: '100%', margin: '16px 0 0 0' }} variant="standard">
                <InputLabel>Rental Service</InputLabel>
                <Select
                  size="small"
                  value={rentalService ? rentalService.id : ''}
                  onChange={handlerentalServiceChange}
                  sx={{ flexGrow: 1 }}
                >
                  <MenuItem value="-">Select</MenuItem>
                  {rentals.map(rental => (
                    <MenuItem key={rental.id} value={rental.id}>{rental.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </DrawerSection>
            <DrawerSection>
              <Box display="flex">
                <Typography>LENGTH OF STAY DISCOUNTS:</Typography>
              </Box>
              {stayLengthDiscounts.length > 0 && (
                <Table
                  sx={{ 'td, th': { padding: 0, borderBottom: 'none' }, tableLayout: 'fixed', marginTop: '1rem' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Stay from</TableCell>
                      <TableCell>Stay To</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell sx={{ width: '55px' }}>MU</TableCell>
                      <TableCell sx={{ width: '32px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {stayLengthDiscounts.map((discount, index) => (
                      <TableRow key={discount.id}>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            autoFocus={true}
                            name="from"
                            value={discount.from}
                            onChange={ev => updateStayLengthDiscountField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            name="to"
                            value={discount.to}
                            onChange={ev => updateStayLengthDiscountField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            name="value"
                            value={discount.value}
                            onChange={ev => updateStayLengthDiscountField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            variant="outlined"
                            size="small"
                            name="mu"
                            value={discount.mu}
                            onChange={ev => updateStayLengthDiscountField(ev, index)}
                          >
                            <MenuItem value="PERCENTAGE">%</MenuItem>
                            <MenuItem value="EURO">€</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => deleteStayLengthDiscount(index)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              <Button
                variant="contained"
                sx={{ width: '100%', marginTop: '1rem' }}
                onClick={addStayLengthDiscount}
              >
                <AddIcon /> Add length of stay discount
              </Button>
            </DrawerSection>
            <DrawerSection>
              <Box display="flex">
                <Typography>LAST MINUTE DISCOUNTS:</Typography>
              </Box>
              {lastMinuteDiscounts.length > 0 && (
                <Table
                  sx={{ 'td, th': { padding: 0, borderBottom: 'none' }, tableLayout: 'fixed', marginTop: '1rem' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>From</TableCell>
                      <TableCell>To</TableCell>
                      <TableCell>Discount</TableCell>
                      <TableCell sx={{ width: '55px' }}>MU</TableCell>
                      <TableCell sx={{ width: '32px' }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lastMinuteDiscounts.map((discount, index) => (
                      <TableRow key={discount.id}>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            autoFocus={true}
                            name="from"
                            value={discount.from}
                            onChange={ev => updateLastMinuteDiscountsField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            name="to"
                            value={discount.to}
                            onChange={ev => updateLastMinuteDiscountsField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <TableInput
                            type="number"
                            variant="outlined"
                            size="small"
                            name="value"
                            value={discount.value}
                            onChange={ev => updateLastMinuteDiscountsField(ev, index)}
                            onFocus={handleInputFocus}
                          />
                        </TableCell>
                        <TableCell>
                          <Select
                            variant="outlined"
                            size="small"
                            name="mu"
                            value={discount.mu}
                            onChange={ev => updateLastMinuteDiscountsField(ev, index)}
                          >
                            <MenuItem value="PERCENTAGE">%</MenuItem>
                            <MenuItem value="EURO">€</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell>
                          <IconButton size="small" onClick={() => deleteLastMinuteDiscount(index)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
              <Button
                variant="contained"
                sx={{ width: '100%', marginTop: '1rem' }}
                onClick={addLastMinuteDiscount}
              >
                <AddIcon /> Add new Last minute discount
              </Button>
            </DrawerSection>
            <DrawerSection>
              <Typography>SERVICE PRICES:</Typography>
              {services.map(service => (
                <DrawerSectionForm key={service.id}>
                  <Typography sx={{ flexGrow: 1 }}>
                    {service.name}
                  </Typography>
                  <PriceInput
                    onFocus={handleInputFocus}
                    value={servicesPrices[service.id] ? servicesPrices[service.id].value : 0}
                    onChange={ev => setSingleServicePrice(service, parseFloat(ev.target.value))}
                    onBlur={ev => validateSingleServicePrice(service)}
                  />
                </DrawerSectionForm>
              ))}
            </DrawerSection>
          </>
        ) : null}
      </DrawerContent>
      <DrawerActions>
        <Button
          variant="contained"
          sx={{ width: '100%', marginTop: '1rem' }}
          onClick={handleConfirmButtonClicked}
        >
          <CheckIcon /> Apply
        </Button>
      </DrawerActions>
    </RightDrawer>
  );
}

export default BulkEditPrices;
