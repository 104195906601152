import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const WizardWrapper = styled('div')`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wizard = styled('div')`
  width: 100%;
  height: 100%;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  overflow: hidden;
  display: flex;
  border-radius: 8px;
  flex-direction: column;
  max-width: calc(100% - 32px);
  max-height: calc(100% - 32px);

  @media all and (min-width: 600px) {
    height: auto;
  }

  @media all and (min-width: 900px) {
    max-width: 800px;
    max-height: 800px;
  }

  @media all and (min-width: 1200px) {
    max-width: 900px;
  }
`;

export const StepperWrapper = styled(Box)`
  padding: 16px 0;
  border-bottom: 1px solid #bdbdbd;

  @media all and (min-height: 900px) {
    padding: 32px 0;
  }
`;

export const WizardStepWrapper = styled(Box)`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px 0;
  width: 100%;

  @media all and (min-width: 900px) {
    padding: 16px 32px;
    width: calc(100% - 64px);
  }

  @media all and (min-width: 1200px) {
    padding: 16px 100px;
    width: calc(100% - 200px);
  }
`;

export const WizardActionsWrapper = styled(Box)`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #bdbdbd;
  
  @media all and (min-width: 900px) {
    padding: 16px 48px;
    width: calc(100% - 96px);
  }

  @media all and (min-width: 1200px) {
    padding: 16px 116px;
    width: calc(100% - 232px);
  }
`;
