import { Checkbox, FormControlLabel } from '@mui/material';
import useMultiSelection from './multi-selection-list.hook';

function MultiSelectionList({ initialValue, options, onChange }) {
  const {
    selected,
    onSelectionChange,
  } = useMultiSelection({ initialValue, onChange });

  return (
    <div>
      {options.map(option => (
        <div key={option.id}>
          <FormControlLabel
            label={option.name}
            control={(
              <Checkbox
                checked={selected[option.id]}
                inputProps={{ 'aria-label': option.name }}
              />
            )}
            onChange={() => onSelectionChange(option.id)}
          />
        </div>
      ))}
    </div>
  );
}

export default MultiSelectionList;
