import { useEffect, useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import theme from '../../theme';

function useDrawerState() {
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [drawerOpened, setDrawerOpened] = useState(true);
  const [drawerMobileOpened, setDrawerMobileOpened] = useState(false);

  useEffect(() => {
    if (!drawerOpened) {
      setDrawerMobileOpened(false);
    }
  }, [drawerOpened]);

  const handleItemClicked = () => {
    if (isMobile) {
      setDrawerMobileOpened(false);
    }
  };

  return {
    isMobile,
    drawerOpened: drawerMobileOpened || isMobile
      ? drawerMobileOpened
      : drawerOpened,
    setDrawerOpened: isMobile ? setDrawerMobileOpened : setDrawerOpened,
    handleItemClicked,
  };
}

export default useDrawerState;
