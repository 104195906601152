import { getSissiCoreUrl } from '../config/server';

export async function getApartments() {
  const url = `${getSissiCoreUrl()}/apartmentsInCategory/list`;

  const res = await fetch(url, { credentials: 'include' });

  if (res.status !== 200) {
    throw await res.json();
  }

  const apartments = await res.json();

  return apartments
    .filter(apt => (apt.cityId === window.currentCompany.sissiCityId))
    .map(apt => ({
      ...apt,
      searchable: `${apt.id} ${apt.fullAddress}`,
    }))
    .sort(function (a, b) {
      // Compare the 2 dates
      if (a.fullAddress < b.fullAddress) return -1;
      if (a.fullAddress > b.fullAddress) return 1;
      return 0;
    });
}
