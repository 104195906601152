import SettingsIcon from '@mui/icons-material/Settings';
import CalculateIcon from '@mui/icons-material/Calculate';
import BedroomParentIcon from '@mui/icons-material/BedroomParent';
import { systemRoles } from 'constants';

const navLinkGroups = [
  {
    title: 'Admin',
    icon: SettingsIcon,
    menuItems: [
      { title: 'Invoicing System', to: '/admin/invoicing-system' },
      { title: 'Services', to: '/admin/services' },
      { title: 'Pricing', to: '/admin/pricing' },
      { title: 'Housekeeping Reasons', to: '/admin/housekeeping-reasons' },
      { title: 'Housekeeping Services', to: '/admin/housekeeping-services' },
      { title: 'Feature Flags', to: '/admin/feature-flags' },
    ],
    requiredRoles: [ systemRoles.ADMIN ]
  },
  {
    title: 'Accounting',
    icon: CalculateIcon,
    menuItems: [
      { title: 'Invoice Plans', to: '/accounting/invoice-plans' },
      { title: 'Rental Deposits', to: '/accounting/rental-deposits' },
    ],
    requiredRoles: [ systemRoles.SALES ]
  },
  {
    title: 'Housekeeping',
    icon: BedroomParentIcon,
    menuItems: [
      { title: 'Open tasks', to: '/housekeeping/protocols' },
      { title: 'Finished tasks', to: '/housekeeping/reports' },
    ],
    requiredRoles: [ systemRoles.HOUSEKEEPING ]
  },
];

export default navLinkGroups;
