import { getSissiInvoicingUrl } from '../config/server';
import { getApartmentCategories } from './apartment-category';

export async function getPrices() {
  const url = `${getSissiInvoicingUrl()}/apartment-category-prices?$limit=1000`;
  const res = await fetch(url, {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}

export async function getApartmentCategoryPrices() {
  const apartmentCategories = await getApartmentCategories();
  const prices = (await getPrices()).data;

  return apartmentCategories.map(apartmentCategory => ({
    ...apartmentCategory,
    prices: prices.find(price => price.apartmentCategoryId === apartmentCategory.id) || {}
  }));
}

export async function postApartmentCategoryPrices(prices) {
  const url = `${getSissiInvoicingUrl()}/apartment-category-prices`;
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'x-service-method': 'bulkCreateUpdate',
      'x-company': window.currentCompanyId,
    },
    body: JSON.stringify(prices),
  });
  if (res.status !== 200 && res.status !== 201) {
    throw await res.json();
  }
  return await res.json();
}
