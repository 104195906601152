import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import useBEDatatable from 'hooks/use-be-datatable';
import TableHeader from 'components/table-header';
import LoadingIndicator from 'components/loading-indicator';
import HousekeepingProtocolReport from 'components/HousekeepingProtocolReport';
import { AppContent, ContentRow, InteractiveRow } from 'styled/components';

import { getHousekeepingReports } from 'api/housekeeping-reports';
import { formatDateTime } from 'utils/date';

function HousekeepingReports() {
  const {
    ready,
    filter,
    rangeEnd,
    rangeStart,
    instancesList,
    selectedInstance,
    totalInstances,
    numberOfPages,
    pages,
    page,

    // Functions
    setPage,
    filterChange,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
  } = useBEDatatable({
    loadInstancesServiceAction: getHousekeepingReports
  });

  if (!ready) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AppContent>
        <TableHeader
          title="Finished Housekeeping Tasks"
          showAddButton={false}
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={totalInstances}
        />
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>Finished</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Issues</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instancesList.length > 0 && instancesList.map((_instance) => (
                  <InteractiveRow
                    key={_instance.id}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                    onClick={() => handleEditButtonClicked(_instance)}
                  >
                    <TableCell>
                      <span style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                        {_instance.Protocol.HousekeepingService.name}
                      </span>
                    </TableCell>
                    <TableCell>
                      {formatDateTime(_instance.completedAt)}
                    </TableCell>
                    <TableCell>{_instance.Protocol.apartment.fullAddress}</TableCell>
                    <TableCell>{_instance.Protocol.apartment.issues || ' - '}</TableCell>
                    <TableCell sx={{ width: 80 }} align="right">
                      <IconButton size="small" onClick={() => {}}>
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </InteractiveRow>
                ))}

                {instancesList.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan={4}>
                      <Typography>There are no Housekeeping Reports.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
        <ContentRow style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
          <Pagination
            count={numberOfPages}
            page={page}
            variant="text"
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            onChange={(ev, page) => setPage(page)}
          />
        </ContentRow>
      </AppContent>
      <HousekeepingProtocolReport
        report={selectedInstance}
        protocol={selectedInstance.Protocol}
        handleClose={() => handleCancelEditButtonClicked()}
        handleConfirm={undefined}
      />
    </>
  );
}

export default HousekeepingReports;
