import { AppBar, IconButton, Toolbar, Typography, } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { DrawerContent, BorderedDrawerSection, RightDrawer, Spacer, } from 'styled/components';

import HousekeepingProtocolHeader from 'components/HousekeepingProtocolHeader';
import HousekeepingProtocolSection from 'components/HousekeepingProtocolSection';

import emptyFunction from 'utils/empty-function';

function HousekeepingProtocolReport({ protocol, report, handleClose }) {
  return (
    <RightDrawer
      open={Boolean(report.id)}
      onOpen={emptyFunction}
      onClose={handleClose}
      aria-labelledby="title"
      anchor="right"
      maxWidth="housekeepingDrawerWidth"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">Housekeeping report</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        {report.id && (
          <>
            <BorderedDrawerSection>
              <HousekeepingProtocolHeader protocol={protocol} report={report} />
            </BorderedDrawerSection>

            <BorderedDrawerSection>
              <Typography><b>Issues:</b></Typography>
              <Typography>{report.issues || ''}</Typography>
            </BorderedDrawerSection>

            {protocol.HousekeepingService.sections.map(housekeepingSection => (
              <BorderedDrawerSection key={housekeepingSection.id}>
                <HousekeepingProtocolSection
                  section={housekeepingSection}
                  sectionValues={report.sectionValues}
                  toggleSectionValue={emptyFunction}
                  disabled={true}
                />
              </BorderedDrawerSection>
            ))}
          </>
        )}
      </DrawerContent>
    </RightDrawer>
  );
}

export default HousekeepingProtocolReport;
