import { Button, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { Link } from 'react-router-dom';
import TableHeader from 'components/table-header';
import LoadingIndicator from 'components/loading-indicator';

import { getInvoicePlans } from './api-services';
import { AppContent, ContentRow } from 'styled/components';
import { INVOICE_STATUSES } from 'pages/invoice-plan/api-services';
import useBEDatatable from 'hooks/use-be-datatable';

function InvoicePlansList() {

  const {
    ready,
    instancesList,
    filter,
    numberOfPages,
    pages,
    page,
    rangeEnd,
    rangeStart,

    // Functions
    setPage,
    filterChange,
  } = useBEDatatable({
    loadInstancesServiceAction: getInvoicePlans
  });

  return (
    <>
      {!ready && (
        <LoadingIndicator />
      )}
      <AppContent>
        <TableHeader
          title="Invoice plans"
          showAddButton={false}
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={instancesList.length}
        />
        <ContentRow>
          {ready && (
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sales Case Id</TableCell>
                    <TableCell>Apartment Address</TableCell>
                    <TableCell sx={{textAlign: 'center'}}>Draft Invoices</TableCell>
                    <TableCell sx={{textAlign: 'center'}}>Sent Invoices</TableCell>
                    <TableCell sx={{textAlign: 'center'}}>Paid Invoices</TableCell>
                    <TableCell sx={{textAlign: 'center'}}>Refunded Invoices</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {instancesList.map((_instance, index) => (
                    <TableRow
                      key={`invoice-plan-${_instance.id}`}
                      sx={{
                        'td': { paddingTop: 1, paddingBottom: 1 },
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                    >
                      <TableCell>
                        <Link
                          to={`/accounting/invoice-plans/${_instance.salesCaseId}`}
                        >
                          {_instance.salesCaseId}
                        </Link>
                      </TableCell>
                      <TableCell dangerouslySetInnerHTML={{__html: _instance.searchable.split(';;')[1]}} />
                      <TableCell sx={{textAlign: 'center'}}>{_instance.invoices.filter(invoice => invoice.status === INVOICE_STATUSES.DRAFT).length}</TableCell>
                      <TableCell sx={{textAlign: 'center'}}>{_instance.invoices.filter(invoice => invoice.status === INVOICE_STATUSES.SENT).length}</TableCell>
                      <TableCell sx={{textAlign: 'center'}}>{_instance.invoices.filter(invoice => invoice.status === INVOICE_STATUSES.PAID).length}</TableCell>
                      <TableCell sx={{textAlign: 'center'}}>{_instance.invoices.filter(invoice => invoice.status === INVOICE_STATUSES.REFUNDED).length}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </ContentRow>
        {numberOfPages > 1 && (
          <ContentRow style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
            <Pagination
              count={numberOfPages}
              page={page}
              variant="text"
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              onChange={(ev, page) => setPage(page)}
            />
          </ContentRow>
        )}
      </AppContent>
    </>
  );
}

export default InvoicePlansList;
