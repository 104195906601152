import React, { useMemo } from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation } from 'react-router-dom';

import { CollapsibleMenuWrapper, ExpandMore } from './styled';
import LinkMenuItem from '../link-menu-item';

function CollapsibleMenu({ expanded: initialExpanded, title, icon: Icon, menuItems, onItemClick }) {
  const location = useLocation();
  const isActive = useMemo(
    () => menuItems.some(item => location.pathname.startsWith(item.to)),
    [location.pathname, menuItems],
  );
  const [expanded, setExpanded] = React.useState(Boolean(initialExpanded || isActive));

  return (
    <CollapsibleMenuWrapper>
      <MenuItem onClick={() => setExpanded(!expanded)}>
        {Icon && (
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
        )}
        <ListItemText
          primaryTypographyProps={{
            className: isActive ? 'active-menu-item' : '',
          }}
        >
          {title}
        </ListItemText>
        <ExpandMore
          expand={expanded}
          aria-label="show more"
          size="small"
        >
          <ExpandMoreIcon fontSize="inherit" />
        </ExpandMore>
      </MenuItem>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {menuItems.map(item => (
          <LinkMenuItem
            {...item}
            onClick={onItemClick}
            active={location.pathname.startsWith(item.to)}
            key={`${title}-${item.title}`}
          />
        ))}
      </Collapse>
    </CollapsibleMenuWrapper>
  );
}

export default CollapsibleMenu;
