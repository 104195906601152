import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

function FormControlSelect({ value, label, options, onChange, keyAttr = 'id', valueAttr = 'label' }) {
  return (
    <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        onChange={onChange}
      >
        {options.map(option => {
          const value = typeof option === 'string' ? option : option[keyAttr];
          const label = typeof option === 'string' ? option : option[valueAttr];
          return (<MenuItem value={value} key={value}>{label}</MenuItem>)
        })}
      </Select>
    </FormControl>
  );
}

export default FormControlSelect;
