import { getSissiInvoicingUrl } from '../config/server';

export const INVOICING_SYSTEM_MODELS = {
  COMPANY: 'COMPANY',
  FULL_COMPANY: 'FULL_COMPANY',
  SERVICE: 'SERVICE',
  CONTACT: 'CONTACT',
  JOURNAL: 'JOURNAL',
};

export async function findInvoicingSystemData(model) {
  const url = `${getSissiInvoicingUrl()}/invoicing-system-data?model=${model}`;
  const res = await fetch(url, {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}

export async function findInvoicingSystemDataWithCredentials(model, config) {
  const url = `${getSissiInvoicingUrl()}/invoicing-system-data?model=${model}`;
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(config),
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-service-method': 'findWithCredentials',
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}
