import { useTheme } from '@mui/material/styles';
import {
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import ConfirmDeletion from '../../components/confirm-deletion';
import LoadingIndicator from '../../components/loading-indicator';
import { deleteFeatureFlag, getFeatureFlags, saveFeatureFlag } from './api-services';
import { AppContent, ContentRow } from 'styled/components';
import ModifyInstance from './modify-instance';
import useDatatable from '../../hooks/use-datatable';
import TableHeader from '../../components/table-header';

function FeatureFlags() {
  const theme = useTheme();

  const validateForm = (instance, _instancesList) => {
    const existingInstance = _instancesList.find(
      _instance => _instance.ffKey === instance.ffKey && _instance.id !== instance.id
    );
    return existingInstance
      ? 'The key is already taken.'
      : null;
  };

  const filterInstances = (instancesList, filter) => {
    debugger;
    return instancesList.filter(
      _instance => _instance.name.toLowerCase().includes(filter.toLowerCase()),
    );
  }

  const {
    ready,
    rangeEnd,
    rangeStart,
    instancesList,
    selectedInstance,
    selectedInstanceError,
    showEditForm,
    showDeleteConfirm,
    filter,

    // Functions
    filterChange,
    saveInstance,
    deleteInstance,

    handleAddButtonClicked,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
    handleDeleteButtonClicked,
    handleCancelDeleteButtonClicked,
  } = useDatatable(getFeatureFlags, filterInstances, saveFeatureFlag, validateForm, deleteFeatureFlag);

  if (!ready) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AppContent>
        <TableHeader
          title="Feature flags"
          showAddButton
          handleAddButtonClicked={handleAddButtonClicked}
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={instancesList.length}
        />
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Key</TableCell>
                  <TableCell>Users</TableCell>
                  <TableCell align="center">Enabled</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instancesList.length > 0 && instancesList.map((_instance) => (
                  <TableRow
                    key={_instance.id}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell>{_instance.name}</TableCell>
                    <TableCell>{_instance.ffKey}</TableCell>
                    <TableCell>{_instance.userEmail}</TableCell>
                    <TableCell sx={{ width: 80 }} align="center">
                      <Switch
                        checked={_instance.enabled}
                        size="small"
                        onChange={() => validateForm({
                          ..._instance,
                          enabled: !_instance.enabled
                        })}
                      />
                    </TableCell>
                    <TableCell sx={{ width: 80 }} align="right">
                      <IconButton onClick={() => handleEditButtonClicked(_instance)}><EditIcon /></IconButton>
                      <IconButton onClick={() => handleDeleteButtonClicked(_instance)}><DeleteIcon
                        sx={{ color: theme.palette.error.light }} /></IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {instancesList.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan={4}>
                      <Typography>There are no Feature flags yet. Add the first one by clicking the "Add new"
                        button</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
      </AppContent>
      {showDeleteConfirm && (
        <ConfirmDeletion
          handleClose={() => handleCancelDeleteButtonClicked(false)}
          handleConfirm={() => deleteInstance(selectedInstance)}
          deleteInstanceMsg={`the Feature flag: ${selectedInstance.name}`}
        />
      )}
      {showEditForm && (
        <ModifyInstance
          handleClose={() => handleCancelEditButtonClicked(false)}
          handleConfirm={saveInstance}
          error={selectedInstanceError}
          instance={selectedInstance}
        />
      )}
    </>
  );
}

export default FeatureFlags;
