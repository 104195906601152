import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import LoadingIndicator from 'components/loading-indicator';
import { AppContent, BlockContentRow, ContentRow, Page, Section } from 'styled/components';

import useInvoicingSystem from './invoicing-system.hook';
import InvoicingSystemConfig from '../../components/invoicing-system-config';

function InvoicingSystem() {
  const {
    ready,
    companies,
    invoicingSystemConfig,
  } = useInvoicingSystem();

  if (!ready) {
    return <LoadingIndicator />;
  }

  return (
    <Page>
      <Section>
        <div>
          <Typography variant="h5" component="h1">Connection parameters</Typography>
        </div>
        <Paper>
          <InvoicingSystemConfig initialValue={invoicingSystemConfig} />
        </Paper>
      </Section>

      <Section>
        <div>
          <Typography variant="h5" component="h1">Companies</Typography>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Invoicing system id</TableCell>
                <TableCell>Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {companies.length > 0 && companies.map((company) => {
                return (
                  <TableRow
                    key={company.id}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell>
                      {company.id}
                    </TableCell>
                    <TableCell>
                      {company.invoicingSystemId}
                    </TableCell>
                    <TableCell>
                      {company.name}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Section>
    </Page>
  );
}

export default InvoicingSystem;
