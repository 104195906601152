import { styled } from '@mui/material/styles';

const LoadingIndicatorWrapper = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.palette.background.default};
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

export { LoadingIndicatorWrapper };
