import { createContext } from 'react';

export const defaultAppContextValue = {
  ready: false,
  currentUser: undefined,
  companies: undefined,
  isAppConfigured: false,
};

export const AppContext = createContext();
