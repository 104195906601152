import { Switch, Table, TableBody, TableCell, TableRow } from '@mui/material';

/**
 * Body of a Multi Answer Housekeeping section
 * @param section
 * @param sectionValues
 * @param toggleSectionValue
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
function HousekeepingProtocolMultiAnswerSectionBody({ section, sectionValues, toggleSectionValue, disabled }) {
  return (
    <Table
      sx={{
        width: '100%',
        '& tr:last-child td': {
          borderBottom: 'none',
        },
        '& td': {
          padding: '8px',
        },
        '& td:first-child': {
          paddingLeft: 0,
        },
        '& td:last-child': {
          paddingRight: 0,
          width: '40px'
        }
      }}
      aria-label="Housekeeping protocol checks"
    >
      <TableBody>
        {section.items.split(/\n/).map((item, index) => (
          <TableRow key={item}>
            <TableCell>
              {item}
            </TableCell>
            <TableCell>
              <Switch
                size="small"
                disabled={disabled}
                checked={Boolean(sectionValues[`${section.id}-${index + 1}`])}
                onChange={() => toggleSectionValue(`${section.id}-${index + 1}`)}
              />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default HousekeepingProtocolMultiAnswerSectionBody;
