import { Box, Button, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const AppContent = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${props => props.theme.spacing(2)};
`;

const ContentRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  
  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(2)};
  }
`;

const Spacer = styled('div')`
  flex-grow: 1;
`;

const InteractiveRow = styled(TableRow)`
  cursor: pointer;
  &.selected, &:hover {
    background: ${props => props.theme.palette.grey['200']};
  }
  &.selected {
    & td {
      font-weight: bold;
    }
    & + tr {
      background: ${props => props.theme.palette.grey['100']};
    }
  }
`;

const ReactivateButton = styled(Button)`
  margin-left: 0.5rem;
`;

const useStyles = makeStyles()(
  theme => ({
    link: {
      color: theme.palette.text.link,
    },
  })
);

export { AppContent, ContentRow, InteractiveRow, Spacer, ReactivateButton, useStyles };
