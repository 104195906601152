import { getSissiCoreUrl } from '../config/server';

export async function getApartmentCategories() {
  const url = `${getSissiCoreUrl()}/apartmentsCategory/list`;
  const res = await fetch(url, { credentials: 'include' });

  if (res.status !== 200) {
    throw await res.json();
  }

  const apartmentCategories = await res.json();

  return apartmentCategories
    .filter(aptCategory => (aptCategory.cityId === window.currentCompany.sissiCityId))
    .map(aptCategory => ({
      ...aptCategory,
      searchable: `${aptCategory.id} ${aptCategory.name}`,
    }))
    .sort(function (a, b) {
      // Compare the 2 dates
      if (a.id > b.id) return -1;
      if (a.id < b.id) return 1;
      return 0;
    });
}
