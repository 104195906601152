import { getSissiInvoicingUrl } from 'config/server';
import { getSalesCase } from '../invoice-plan/api-services';

async function getInvoicePlans(params = {}) {
  const filter = params.filter || undefined;
  const $skip = params.skip || 0;
  const $limit = params.limit || 50;

  const urlSearchParams = new URLSearchParams({
    '$skip': $skip,
    '$limit': $limit,
    ...(filter ? { "searchable[$like]": `%${filter}%` } : {})
  });

  const url = new URL(`${getSissiInvoicingUrl()}/invoice-plan`);
  url.search = urlSearchParams.toString();

  const res = await fetch(decodeURIComponent(url.toString()), {
    credentials: 'include',
    mode:'cors',
    headers: {
      'Content-Type': 'application/json',
      'x-company': window.currentCompanyId,
    },
  });
  if (res.status !== 200) {
    throw await res.json();
  }
  return await res.json();
}

export {
  getInvoicePlans,
};
