import InputAdornment from '@mui/material/InputAdornment';
import { StyledPriceInput } from './styled';

function PriceInput(props) {
  return (
    <StyledPriceInput
      type="number"
      variant="outlined"
      size="small"
      {...props}
      endAdornment={<InputAdornment position="end">€</InputAdornment>}
    />
  );
}

export default PriceInput;
