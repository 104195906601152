import { getSissiInvoicingUrl } from "config/server";

const getRequestConfig = (params = {}) => ({
  credentials: 'include',
  mode:'cors',
  headers: {
    'Content-Type': 'application/json',
    'x-company': window.currentCompanyId,
  },
  ...params,
});

export async function findHousekeepingReasons(params = {}) {
  const filter = params.filter || undefined;
  const $skip = params.skip || 0;
  const $limit = params.limit || 50;
  const skipPagination = params.skipPagination || false;

  const urlSearchParams = new URLSearchParams({
    '$skip': $skip,
    '$limit': skipPagination ? 1000 : $limit,
    [`$sort[title]`]: 1,
    ...(filter ? { "searchable[$like]": `%${filter}%` } : {})
  });

  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocol-reasons`);
  url.search = urlSearchParams.toString();

  const requestConfig = getRequestConfig();
  const res = await fetch(decodeURIComponent(url.toString()), requestConfig);

  if (res.status !== 200) {
    throw await res.json();
  }

  if (skipPagination) {
    return (await res.json()).data;
  }

  return (await res.json());
}

export async function saveHousekeepingReason(data = {}) {
  if (!data.id) {
    return await createHousekeepingReason(data);
  }
  return await updateHousekeepingReason(data);
}

export async function createHousekeepingReason(data = {}) {
  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocol-reasons`);

  const requestConfig = getRequestConfig({
    method: 'POST',
    body: JSON.stringify(data),
  });
  const res = await fetch(url.toString(), requestConfig);

  if (res.status !== 201) {
    throw await res.json();
  }
  return (await res.json());
}

export async function updateHousekeepingReason(data = {}) {
  const url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocol-reasons`);
  if (!data.id) {
    return Promise.resolve(undefined);
  }

  const requestConfig = getRequestConfig({
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  const res = await fetch(url.toString(), requestConfig);

  if (res.status !== 200) {
    throw await res.json();
  }
  return (await res.json());
}

export async function deleteHousekeepingReason(data) {
  let url = new URL(`${getSissiInvoicingUrl()}/housekeeping/protocol-reasons`);
  if (!data.id) {
    return Promise.resolve(undefined);
  }

  const requestConfig = getRequestConfig({
    method: 'DELETE',
  });
  const res = await fetch(`${url.toString()}/${data.id}`, requestConfig);
  if (res.status !== 200) {
    throw await res.json();
  }
  return Promise.resolve();
}
