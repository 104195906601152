import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import ErrorIcon from '@mui/icons-material/Error';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import TableHeader from 'components/table-header';
import LoadingIndicator from 'components/loading-indicator';
import { AppContent, ContentRow, InteractiveRow } from 'styled/components';
import ConfirmDeletion from 'components/confirm-deletion';

import HousekeepingProtocolForm from 'components/HousekeepingProtocolForm';
import { createExtraHousekeepingProtocols, deleteHousekeepingProtocol, getHousekeepingProtocols } from 'api/housekeeping-protocols';
import { saveHousekeepingReport } from 'api/housekeeping-reports';
import { formatDateTime } from 'utils/date';

import AddExtraHousekeepingProtocol from './AddExtraHousekeepingProtocol';
import useHousekeepingProtocols from './useHousekeepingProtocols';

function HousekeepingProtocols() {
  const {
    ready,
    filter,
    rangeEnd,
    rangeStart,
    addExtraItem,
    instancesList,
    totalInstances,
    selectedInstance,
    page,
    numberOfPages,
    showDeleteConfirm,

    // Functions
    filterChange,
    setAddExtraItem,
    reloadInstances,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
    setPage,
    handleDeleteButtonClicked,
    setShowDeleteConfirm,
  } = useHousekeepingProtocols(getHousekeepingProtocols);

  if (!ready) {
    return <LoadingIndicator />;
  }

  const handleSaveClicked = (data) => {
    saveHousekeepingReport(data)
      .then(res => {
        handleCancelEditButtonClicked();
        reloadInstances();
      });
  };

  const deleteProtocol = () => {
    deleteHousekeepingProtocol(selectedInstance)
      .then(() => {
        handleCancelEditButtonClicked();
        reloadInstances();
      });
    setShowDeleteConfirm(false);
  };

  const cancelProtocolDeletion = () => {
    setShowDeleteConfirm(false);
  };

  const handleSaveExtraItemClicked = (item) => {
    createExtraHousekeepingProtocols(item).then(() => {
      setAddExtraItem(false);
      reloadInstances();
    });
  };

  return (
    <>
      <AppContent>
        <TableHeader
          title="Open Housekeeping Tasks"
          showAddButton
          handleAddButtonClicked={() => setAddExtraItem(!addExtraItem)}
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={totalInstances}
        />
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell>From</TableCell>
                  <TableCell>To</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instancesList.length > 0 && instancesList.map((_instance) => {
                  const isDelayed = _instance.to && new Date().getTime() > new Date(_instance.to).getTime();
                  return (
                    <InteractiveRow
                      key={_instance.id}
                      sx={{
                        'td': { paddingTop: 1, paddingBottom: 1 },
                        '&:last-child td, &:last-child th': { border: 0 }
                      }}
                      onClick={() => handleEditButtonClicked(_instance)}
                    >
                      <TableCell>
                        <Box display="flex" alignItems="center" columnGap={1}>
                          {_instance.HousekeepingService.name}
                          {(isDelayed || _instance.danger) && (
                            <ErrorIcon color={isDelayed ? 'error' : 'yellow'} />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        {formatDateTime(_instance.from)}
                      </TableCell>
                      <TableCell>
                        {_instance.to ? formatDateTime(_instance.to) : '-'}
                      </TableCell>
                      <TableCell
                        dangerouslySetInnerHTML={{
                          __html: _instance.apartment.fullAddress,
                        }}
                      />
                      <TableCell sx={{ width: 80 }} align="right">
                        <IconButton size="small">
                          <ChevronRightIcon />
                        </IconButton>
                      </TableCell>
                    </InteractiveRow>
                  );
                })}

                {instancesList.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan={4}>
                      <Typography>There are no Housekeeping items pending.</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
        <ContentRow style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
          <Pagination
            count={numberOfPages}
            page={page}
            variant="text"
            shape="rounded"
            color="primary"
            showFirstButton
            showLastButton
            onChange={(ev, page) => setPage(page)}
          />
        </ContentRow>
      </AppContent>
      <HousekeepingProtocolForm
        protocol={selectedInstance}
        report={selectedInstance.Report}
        handleClose={() => handleCancelEditButtonClicked()}
        handleConfirm={handleSaveClicked}
        handleDelete={handleDeleteButtonClicked}
      />
      <AddExtraHousekeepingProtocol
        open={addExtraItem}
        handleClose={() => setAddExtraItem(false)}
        handleConfirm={handleSaveExtraItemClicked}
      />
      {showDeleteConfirm && (
        <ConfirmDeletion
          handleConfirm={deleteProtocol}
          handleClose={cancelProtocolDeletion}
          deleteInstanceMsg={`this protocol`}
        />
      )}
    </>
  );
}

export default HousekeepingProtocols;
