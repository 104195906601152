import { useContext, useState } from 'react';
import { Select, MenuItem } from '@mui/material';
import { AppContext } from '../../state/app-context';

function CurrentCompanySelector(props) {
  const { companies } = useContext(AppContext);
  const [currentCompanyId] = useState(window.currentCompanyId);

  const handleCurrentCompanyChange = event => {
    window.localStorage.setItem('currentCompanyId', event.target.value);
    window.location.reload();
  };

  return (
    <Select
      value={currentCompanyId}
      onChange={handleCurrentCompanyChange}
      {...props}
    >
      {companies.map(company => (
        <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
      ))}
    </Select>
  );
}

export default CurrentCompanySelector;
