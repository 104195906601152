import { getCurrentUser } from './current-user';
import { getCompanies } from './company';
import { getInvoicingSystemConfig } from './invoicing-system-config';

export async function loadInitialData() {
  let invoicingSystemConfig = undefined;
  let currentUser = undefined;
  let companies = undefined;

  try {
    currentUser = await getCurrentUser();
  } catch (err) {
    console.error(err);
  }

  try {
    invoicingSystemConfig = await getInvoicingSystemConfig();
  } catch (err) {
    console.error(err);
  }

  try {
    const companiesResponse = await getCompanies();
    companies = companiesResponse.data;
  } catch (err) {
    console.error(err);
  }

  if (companies && currentUser && currentUser.email === 'di@viennaresidence.com') {
    companies = companies.filter(c => c.name === 'WWR – World Wide Residence GmbH');
  }

  return {
    invoicingSystemConfig,
    currentUser,
    companies,
  }
}
