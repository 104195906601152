import { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { HousekeepingSection, } from './styled';
import './ckeditor.css';
import Switch from '@mui/material/Switch';
import { FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';

function ModifyHousekeepingSection({ instance, handleClose, handleConfirm }) {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [items, setItems] = useState('');
  const [required, setRequired] = useState(false);

  useEffect(() => {
    if (!instance) {
      return;
    }
    setType(instance.type || '');
    setTitle(instance.title || '');

    setContent(instance.content || []);
    setItems(instance.items || '');

    setRequired(instance.required);
  }, [instance]);

  const handleConfirmButtonClicked = () => {
    handleConfirm({
      ...instance,
      type,
      title,
      content: type === 'MULTI_ANSWER' ? undefined : content,
      items: type === 'SINGLE_ANSWER' ? undefined : items,
      required,
    });
  };

  const sectionIncomplete = title.length === 0 || ((type === 'SINGLE_ANSWER' && content.length === 0) || (type === 'MULTI_ANSWER' && items.length === 0));

  return (
    <HousekeepingSection>
      <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
        <InputLabel>Type</InputLabel>
        <Select value={type} onChange={ev => setType(ev.target.value)}>
          <MenuItem value="SINGLE_ANSWER">Single Answer</MenuItem>
          <MenuItem value="MULTI_ANSWER">Multi Answer</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
        <TextField
          label="Title"
          value={title}
          variant="standard"
          onChange={ev => setTitle(ev.target.value)}
        />
      </FormControl>
      {type === 'SINGLE_ANSWER' && (
        <Box sx={{ marginTop: '1rem' }}>
          <InputLabel shrink htmlFor="content-editor">Content</InputLabel>
          <CKEditor
            id="content-editor"
            editor={ClassicEditor}
            config={{
              toolbar: ['undo', 'redo', '|', 'bold', 'italic', 'bulletedList', 'numberedList', '|', 'outdent', 'indent']
            }}
            data={content}
            onChange={(_, editor) => {
              setContent(editor.getData());
            }}
          />
        </Box>
      )}
      {type === 'MULTI_ANSWER' && (
        <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
          <TextField
            label="Items (One per line)"
            multiline
            value={items}
            variant="standard"
            onChange={ev => setItems(ev.target.value)}
          />
        </FormControl>
      )}
      <FormControlLabel
        control={
          <Switch
            checked={required}
            onChange={() => setRequired(!required)}
            inputProps={{ 'aria-label': 'Required for finishing the protocol' }}
            sx={{ margin: 0 }}
          />
        }
        label="Required for finishing the protocol"
      />
      <Box display="flex" justifyContent="flex-end">
        <Button disabled={sectionIncomplete} onClick={handleConfirmButtonClicked}>Save</Button>
        <Button type="button" color="error" onClick={handleClose}>Cancel</Button>
      </Box>
    </HousekeepingSection>
  );
}

export default ModifyHousekeepingSection;
