import { Button, InputAdornment, TextField, Typography, } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';

import { ContentRow, Spacer } from 'styled/components';
import { Actions, TableCounter } from './styled';
import { useState } from 'react';

function TableHeader({
  title,
  showAddButton = false,
  handleAddButtonClicked = undefined,
  children,
  showFilter = true,
  filter,
  filterChange,
  firstItemIndex,
  lastItemIndex,
  totalItems,
}) {
  let timer = null;
  const [internalFilter, setInternalFilter] = useState(filter);

  const onInternalFilterChange = ev => {
    clearTimeout(timer);
    setInternalFilter(ev.target.value);
    timer = setTimeout(() => filterChange(ev), 300);
  };

  return (
    <div>
      <ContentRow>
        <Typography variant="h5" component="h1">{title}</Typography>
        <Spacer />
        <Actions>
          {children}
          {showAddButton && (
            <Button variant="contained" onClick={handleAddButtonClicked}>Add new</Button>
          )}
        </Actions>
      </ContentRow>
      {showFilter && (
        <ContentRow>
          <TextField
            sx={{ marginRight: 3 }}
            size="small"
            value={internalFilter}
            onChange={onInternalFilterChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Spacer />
          {Boolean(lastItemIndex && lastItemIndex > 0) && (
            <TableCounter>Showing {firstItemIndex} - {lastItemIndex} of {totalItems}</TableCounter>
          )}
        </ContentRow>
      )}
    </div>
  );
}

export default TableHeader;
