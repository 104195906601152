import { getSissiCoreUrl } from '../../config/server';

function getFeatureFlags() {
  const url = `${getSissiCoreUrl()}/featureFlag`;
  return fetch(url, { credentials: 'include' })
    .then(res => res.json())
    .catch(() => Promise.resolve([]));
}

function saveFeatureFlag(instance) {
  const url = `${getSissiCoreUrl()}/featureFlag/${instance.id ? 'edit' : 'create'}${instance.id ? `/${instance.id}` : ''}`;
  return fetch(
    url,
    {
      method: instance.id ? 'PUT' : 'POST',
      credentials: 'include',
      body: JSON.stringify(instance),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
    .then(res => res.json())
    .then(json => json.error ? Promise.resolve(null) : Promise.resolve(json))
    .catch(() => Promise.resolve(null));
}

function deleteFeatureFlag(instance) {
  const url = `${getSissiCoreUrl()}/featureFlag/delete/${instance.id}`;
  return fetch(url, { method: 'DELETE', credentials: 'include' })
    .then(() => Promise.resolve(true))
    .catch(() => Promise.resolve(false));
}

export { getFeatureFlags, saveFeatureFlag, deleteFeatureFlag };
