import { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Add as AddIcon,
  Refresh as RefreshIcon,
  Send as SendIcon,
  CancelScheduleSend as CancelScheduleSendIcon,
}from '@mui/icons-material';

import { AppContext } from 'state/app-context';
import LoadingIndicator from 'components/loading-indicator';
import {
  INVOICE_STATUSES,
  stringToColour,
  formatDate,
  addInvoiceItem,
  calculateInvoiceTotal,
  deleteInvoiceItem,
  getOrCreateSalesCaseInvoicePlan,
  updateInvoiceItem,
  syncInvoicesStatuses,
  createAndSendInvoice,
  refundInvoice,
  isInvoiceEditable,
  isInvoiceVisible,
  sortInvoices,
  getCheckInAndCheckOut,
} from './api-services';
import { AppContent, ContentRow, Spacer } from 'styled/components';
import Confirm from 'components/confirm';
import { EmptyPlaceholderContainer, InteractiveRow, InvoiceDate, InvoiceHeader, } from './styled';

import AddInvoiceItemModal from './add-invoice-item';
import Invoice from './invoice';
import { getServices } from '../../api/service';
import { nightsBetween } from 'utils/date';

function InvoicePlan() {
  const [ready, setReady] = useState(false);
  const { companies } = useContext(AppContext);
  const { salesCaseId } = useParams();

  const [salesCase, setSalesCase] = useState(null);
  const [services, setServices] = useState([]);
  const [invoicePlan, setInvoicePlan] = useState({ invoices: [] });
  const [error, setError] = useState(null);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceItem, setInvoiceItem] = useState(null);

  const [renderAddEditInvoiceItem, setRenderAddEditInvoiceItem] = useState(true);
  const [invoiceItemModalOpen, setInvoiceItemModalOpen] = useState(false);

  const [confirmMessage, setConfirmMessage] = useState(null);
  const [confirmAction, setConfirmAction] = useState(null);

  const [sendingInvoice, setSendingInvoice] = useState(null);

  const getInvoicePlan = (forceReload = false) => {
    setSalesCase(null);
    setInvoicePlan({ invoices: [] });
    setReady(false);
    getOrCreateSalesCaseInvoicePlan(companies, salesCaseId, forceReload)
      .then((invoicePlanData) => {
        if (invoicePlanData) {
          setSalesCase(invoicePlanData.salesCase);
          setInvoicePlan(invoicePlanData.invoicePlan);
        }

        setReady(true);
      })
      .catch(err => {
        setReady(true);
      });
  };

  useEffect(() => {
    if (salesCaseId && !isNaN(parseInt(salesCaseId))) {
      getInvoicePlan();
    } else {
      setReady(true);
      setError('Invalid Sales Case Id');
    }
  }, []);

  useEffect(() => {
    getServices().then(servicesRes => setServices(servicesRes.data));
  }, []);

  useEffect(() => {
    if (
      !invoicePlan ||
      !invoicePlan.invoices.length ||
      !invoicePlan.invoices.some(it => it.invoicingSystemId && it.status !== INVOICE_STATUSES.REFUNDED)
    ) {
      return () => {};
    }

    const syncInvoiceStatusesCb = () => {
      syncInvoicesStatuses(salesCase).then((newInvoicePlan) => {
        if (newInvoicePlan.updatedAt !== invoicePlan.updatedAt) {
          setInvoicePlan(newInvoicePlan);
        }
      });
    };

    const interval = setInterval(syncInvoiceStatusesCb, 60000);
    syncInvoiceStatusesCb();

    return () => {
      clearInterval(interval);
    }
  }, [invoicePlan, salesCase]);

  const closeInvoiceItemModal = async () => {
    setInvoiceItem(null);
    setInvoiceItemModalOpen(false);
    setRenderAddEditInvoiceItem(false);
    setTimeout(() => {
      setRenderAddEditInvoiceItem(true);
    }, 500);
  };

  const selectInvoiceItemForEdit = (invoice, invoiceItem) => {
    if (typeof selectedInvoice.from === 'string') {
      selectedInvoice.from = new Date(selectedInvoice.from);
    }
    if (typeof selectedInvoice.to === 'string') {
      selectedInvoice.to = new Date(selectedInvoice.to);
    }

    setInvoiceItem({
      ...invoiceItem,
      minDate: selectedInvoice.from,
      maxDate: new Date(selectedInvoice.to.getTime() - 1),
      isEditable: isInvoiceEditable(invoice),
    });
    setInvoiceItemModalOpen(true);
  };

  const handleAddInvoiceItem = async (invoiceItemParams) => {
    try {
      const invoicePlan = await addInvoiceItem(salesCase, invoiceItemParams);
      setInvoicePlan(invoicePlan);
      await closeInvoiceItemModal();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateInvoiceItem = async (invoiceItemParams) => {
    const invoicePlan = await updateInvoiceItem(salesCase, invoiceItemParams);
    setInvoiceItem(null);
    setInvoicePlan(invoicePlan);
    await closeInvoiceItemModal();
  };

  const handleDeleteInvoiceItem = async (invoiceItemParams) => {
    const invoicePlan = await deleteInvoiceItem(salesCase, invoiceItemParams);
    setInvoiceItem(null);
    setInvoicePlan(invoicePlan);
    await closeInvoiceItemModal();
  };

  const handleInvoiceActionConfirmed = (invoice, nextFunction) => {
    setSendingInvoice(true)
    nextFunction(salesCase, invoice)
      .then(updatedInvoicePlan => {
        if (updatedInvoicePlan) {
          setInvoicePlan(updatedInvoicePlan);
        }
        setSendingInvoice(false);
      })
      .catch(err => {
        setSelectedInvoice(false);
        console.error(err);
      });
  };

  const handleInvoiceAction = (ev, invoice) => {
    ev.preventDefault();
    ev.stopPropagation();

    let message = 'This invoice has already been sent. Do you want to <b>cancel</b> it?';
    let nextFunction = refundInvoice;

    if (invoice.status === INVOICE_STATUSES.DRAFT) {
      message = 'Do you want to send the invoice?';
      nextFunction = createAndSendInvoice;
    } else if (invoice.status === INVOICE_STATUSES.PAID) {
      message = 'This invoice has already been paid. Do you want to <b>unreconcile</b> and <b>cancel</b> it?';
    }

    setConfirmMessage(message);
    setConfirmAction(() => () => {
      setConfirmMessage(null);
      handleInvoiceActionConfirmed(invoice, nextFunction);
    });
  }

  const baseRate = useMemo(() => {
    if (!invoicePlan || !invoicePlan.pricingResume || !salesCase) {
      return undefined;
    }

    const { rentalPrice, rentalPriceCurrency, rentalPricePeriodicity } = invoicePlan.pricingResume;
    const { checkin, checkout} = getCheckInAndCheckOut(salesCase);
    const totalNights = nightsBetween(checkin, checkout);

    const dailyRateLabel = [
      rentalPrice.toFixed(2),
      rentalPriceCurrency,
      'per night',
    ];

    const montlyRateLabel = [
      (totalNights < 28
        ? invoicePlan.invoices.reduce((total, invoice) => {
          return total + invoice.items.reduce((subtotal, invoiceItem) => {
            return subtotal + (invoiceItem.isRentalService ? invoiceItem.price : 0);
          }, 0);
        }, 0)
        : rentalPrice * 30
      ).toFixed(2),
      rentalPriceCurrency,
      rentalPricePeriodicity === 'PER_MONTH' ? ' per month' : ' total',
    ];

    return [
      dailyRateLabel.join(' '),
      montlyRateLabel.join(' '),
    ].join(' / ');
  }, [invoicePlan]);

  if (!ready) {
    return <LoadingIndicator />;
  }

  if (error) {
    return (
      <EmptyPlaceholderContainer>
        <Typography>${error}</Typography>
      </EmptyPlaceholderContainer>
    );
  }

  if (!salesCase) {
    return (
      <EmptyPlaceholderContainer>
        <Typography>The sales case with id <b>{salesCaseId}</b> does not exist.</Typography>
      </EmptyPlaceholderContainer>
    );
  }

  return (
    <>
      {sendingInvoice && (<LoadingIndicator />)}
      <AppContent>
        <ContentRow>
          <Typography variant="h5" component="h1">
            Invoice plan {salesCaseId}
          </Typography>
          <Spacer />
          <Box display="flex" alignItems="center" columnGap={2}>
            {baseRate && (
              <Typography>Rental: <b>{baseRate}</b></Typography>
            )}
            {(salesCase.status < 7 || true) && (
              <Button
                variant="contained"
                onClick={() => getInvoicePlan(true)}
                startIcon={<RefreshIcon />}
                title="Refresh"
              >
                <span>Refresh</span>
              </Button>
            )}
            {salesCase.status < 23 && (
              <Button
                variant="contained"
                onClick={() => setInvoiceItemModalOpen(true)}
                startIcon={<AddIcon />}
                title="Add Invoice Item"
              >
                <span>Add Invoice Item</span>
              </Button>
            )}
          </Box>
        </ContentRow>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Invoice Recipient</TableCell>
                <TableCell>Invoice Number</TableCell>
                <TableCell>Invoice Date</TableCell>
                <TableCell>Due Date</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Status</TableCell>
                <TableCell sx={{ width: 5 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoicePlan.invoices.length > 0 && invoicePlan.invoices.filter(isInvoiceVisible).sort(sortInvoices).map(invoice => (
                <Fragment key={invoice.id}>
                  <InteractiveRow
                    className={selectedInvoice && selectedInvoice.id === invoice.id ? 'selected' : ''}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                    onClick={() => setSelectedInvoice(selectedInvoice && selectedInvoice.id === invoice.id ? null : invoice)}
                  >
                    <TableCell sx={{ padding: '8px 0 8px 16px !important', width: 56 }}>
                      <InvoiceHeader>
                        <InvoiceDate style={{ background: stringToColour(`01-${invoice.month}-${invoice.year}`) }}>
                          <span>{invoice.month}</span>
                          <span>-</span>
                          <span>{invoice.year}</span>
                        </InvoiceDate>
                      </InvoiceHeader>
                    </TableCell>
                    <TableCell>{invoice.contractContact}</TableCell>
                    <TableCell>{invoice.invoiceNumber || '-'}</TableCell>
                    <TableCell>{formatDate(invoice.invoiceDate)}</TableCell>
                    <TableCell>{formatDate(invoice.dueDate)}</TableCell>
                    <TableCell>€ {calculateInvoiceTotal(invoice).toFixed(2)}</TableCell>
                    <TableCell>{invoice.status || INVOICE_STATUSES.DRAFT}</TableCell>
                    <TableCell>
                      {calculateInvoiceTotal(invoice).toFixed(2) > 0 && (
                        <IconButton
                          disabled={salesCase.status < 7}
                          size="small"
                          color={isInvoiceEditable(invoice) || invoice.status !== INVOICE_STATUSES.PAID ? 'primary' : 'secondary'}
                          onClick={(ev) => handleInvoiceAction(ev, invoice)}
                        >
                          {isInvoiceEditable(invoice) ? (
                            <SendIcon />
                          ): (
                            <CancelScheduleSendIcon />
                          )}
                        </IconButton>
                      )}
                    </TableCell>
                  </InteractiveRow>
                  {selectedInvoice && invoice.id === selectedInvoice.id && (
                    <tr>
                      <td colSpan="9" style={{ padding: '16px' }}>
                        <Invoice
                          invoice={invoice}
                          selectInvoiceItemForEdit={invoiceItem => selectInvoiceItemForEdit(invoice, invoiceItem)}
                        />
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </AppContent>
      {renderAddEditInvoiceItem && (
        <AddInvoiceItemModal
          sc={salesCase}
          instance={invoiceItem}
          open={invoiceItemModalOpen}
          handleConfirm={handleAddInvoiceItem}
          handleUpdate={handleUpdateInvoiceItem}
          handleDelete={handleDeleteInvoiceItem}
          handleClose={closeInvoiceItemModal}
          editable={!invoiceItem || invoiceItem.isEditable}
        />
      )}
      {confirmMessage && (
        <Confirm
          title="Confirm"
          message={confirmMessage}
          handleClose={() => setConfirmMessage('')}
          handleConfirm={() => confirmAction()}
        />
      )}
    </>
  );
}

export default InvoicePlan;
