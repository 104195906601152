import Typography from '@mui/material/Typography';

import { getSissiCoreUrl } from '../../config/server';
import { AppContent } from './styled';

function Unauthorized() {
  return (
    <AppContent>
      <Typography>
        You are not authenticated, please go to <a href={getSissiCoreUrl()}>Sissi</a>, authenticate and return.
      </Typography>
    </AppContent>
  );
}

export default Unauthorized;
