import { Table, TableBody, TableCell, TableRow, } from '@mui/material';

import { formatDate, formatDateFrame, formatDateTime, formatTimeFrame } from '../../utils/date';
import { getWebsiteUrl } from '../../config/server';

function HousekeepingProtocolHeader({ protocol, report }) {
  return (
    <Table sx={{
      width: '100%',
      '& td': { padding: '16px' },
      '& td:first-child': { paddingLeft: 0 },
      '& td:last-child': { paddingRight: 0 }
    }} aria-label="Description table">
      <TableBody>
        {protocol.salesCaseId && (
          <TableRow>
            <TableCell sx={{ width: 70 }}><b>Sales case:</b></TableCell>
            <TableCell>{protocol.salesCaseId}, {protocol.scOwner}</TableCell>
          </TableRow>
        )}
        {protocol.ProtocolReason && (
          <TableRow>
            <TableCell sx={{ width: 70 }}><b>Reason:</b></TableCell>
            <TableCell>{protocol.ProtocolReason.title}</TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell sx={{ width: 70 }}><b>Address:</b></TableCell>
          <TableCell>
            <a
              href={`${getWebsiteUrl()}/${protocol.apartment.url}`}
              target="_blank"
              rel="noreferrer"
            >
              {protocol.apartment.fullAddress}
            </a>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ width: 70 }}><b>Date:</b></TableCell>
          <TableCell>{formatDate(protocol.from)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ width: 70 }}><b>Time frame:</b></TableCell>
          <TableCell>{formatTimeFrame(protocol.from, protocol.to)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ width: 70 }}><b>Comments:</b></TableCell>
          <TableCell>
            <pre style={{ margin: 0 }}>{protocol.description || '-'}</pre>
          </TableCell>
        </TableRow>
        {(report && report.completedAt) && (
          <TableRow>
            <TableCell sx={{ width: 70 }}><b>Housekeeper:</b></TableCell>
            <TableCell>{window.currentUser.name}</TableCell>
          </TableRow>
        )}
        {(report && report.completedAt) && (
          <TableRow>
            <TableCell sx={{ width: 70 }}><b>Finished:</b></TableCell>
            <TableCell>{formatDateTime(report.completedAt)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

export default HousekeepingProtocolHeader;
