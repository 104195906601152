import { useEffect, useState } from 'react';
import { AppBar, Button, FormControlLabel, IconButton, Switch, Toolbar, Typography, } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import LoadingIndicator from 'components/loading-indicator';
import FormControlSelect from 'components/form-control-select';
import { DrawerActions, DrawerContent, DrawerSection, RightDrawer, Spacer } from 'styled/components';

import { findInvoicingSystemData, INVOICING_SYSTEM_MODELS } from 'api/invoicing-system-data';
import { serviceType, serviceTypeOptions, notSetOption, yesNoOptions } from '../../constants';

function BulkEditServices({ selectedInstances, handleClose, handleConfirm, error, open }) {
  const [ready, setReady] = useState(false);

  const [instances, setInstances] = useState([]);
  const [type, setType] = useState(notSetOption.id);
  const [invoicingSystemJournalId, setInvoicingSystemJournalId] = useState(notSetOption.id);

  const [journalOptions, setJournalOptions] = useState([]);

  useEffect(() => (open ? onOpen() : onClose()), [open]);

  const onOpen = () => {
    findInvoicingSystemData(INVOICING_SYSTEM_MODELS.JOURNAL)
      .then(populateState)
      .catch(err => {
        handleClose();
        console.error(err);
      });
  };

  const populateState = (journalsResponse) => {
    const instancesValues = Object.values(selectedInstances);

    let doAllInstancesHaveTheSameValues = true;
    const [firstInstance] = instancesValues;
    for (let i = 1; i < instancesValues.length; i++) {
      const currentInstance = instancesValues[i];
      if (
        currentInstance.isRentalService !== firstInstance.isRentalService
        || currentInstance.isServiceFee !== firstInstance.isServiceFee
        || currentInstance.isFinalCleaningService !== firstInstance.isFinalCleaningService
        || currentInstance.invoicingSystemJournalId !== firstInstance.invoicingSystemJournalId
      ) {
        doAllInstancesHaveTheSameValues = false;
      }
    }

    if (doAllInstancesHaveTheSameValues) {
      if (firstInstance.isRentalService) {
        setType(serviceType.RENTAL.id)
      }
      else if (firstInstance.isServiceFee) {
        setType(serviceType.SERVICE_FEE.id)
      }
      else if (firstInstance.isFinalCleaningService) {
        setType(serviceType.FINAL_CLEANING.id)
      }
      else {
        setType(serviceType.REGULAR.id)
      }
      setInvoicingSystemJournalId(firstInstance.invoicingSystemJournalId || notSetOption.id);
    }

    setInstances(instancesValues);
    setJournalOptions([
      notSetOption,
      ...(journalsResponse.data.map(item => ({
        id: item.id,
        label: item.name
      }))),
    ]);
    setReady(true);
  };

  const onClose = () => {
    setReady(false);
    setType(notSetOption.id);
    setInvoicingSystemJournalId(notSetOption.id);
    setJournalOptions([]);
  };

  const handleConfirmButtonClicked = () => {
    const modifiedInstances = instances.map(instance => ({
      ...instance,
      isRentalService: instances.length === 1 || type !== notSetOption.id
        ? type === serviceType.RENTAL.id
        : undefined,
      isServiceFee: instances.length === 1 || type !== notSetOption.id
        ? type === serviceType.SERVICE_FEE.id
        : undefined,
      isFinalCleaningService: instances.length === 1 || type !== notSetOption.id
        ? type === serviceType.FINAL_CLEANING.id
        : undefined,
      invoicingSystemJournalId: invoicingSystemJournalId !== notSetOption.id
        ? invoicingSystemJournalId
        : undefined,
    }));
    handleConfirm(modifiedInstances);
  };

  const title = instances.length === 1
    ? `Edit ${instances[0].name}`
    : `Bulk edit ${instances.length} services`;

  const isFormReady = instances.length === 1
    ? invoicingSystemJournalId !== notSetOption.id
    : (
      type !== notSetOption.id ||
      invoicingSystemJournalId !== notSetOption.id
    );

  return (
    <RightDrawer
      open={open}
      onOpen={() => {
      }}
      onClose={handleClose}
      anchor="right"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">{title}</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {ready ? (
        <DrawerContent>
          <DrawerSection>
            <FormControlSelect
              value={type}
              label="Type"
              options={serviceTypeOptions}
              onChange={ev => setType(ev.target.value)}
            />
          </DrawerSection>
          <DrawerSection>
            <FormControlSelect
              value={invoicingSystemJournalId}
              label="Journal"
              options={journalOptions}
              onChange={ev => setInvoicingSystemJournalId(ev.target.value)}
            />
          </DrawerSection>
        </DrawerContent>
      ) : (
        <DrawerContent>
          <LoadingIndicator />
        </DrawerContent>
      )}
      
      {ready && (
        <DrawerActions>
          <Button
            variant="contained"
            disabled={!isFormReady}
            sx={{ width: '100%', marginTop: '1rem' }}
            onClick={handleConfirmButtonClicked}
          >
            <CheckIcon /> Apply
          </Button>
        </DrawerActions>
      )}
    </RightDrawer>
  );
}

export default BulkEditServices;
