import { useEffect, useState } from 'react';
import useSearch from './use-search';

let timeout = undefined;
let requestIndex = undefined;

function useBEDatatable({
  initialInstancesPerPage = 50,
  loadInstancesServiceAction,
  saveInstanceServiceAction = null,
  validateInstanceBeforeSave = null,
  deleteInstanceServiceAction = null,
}) {
  const [ready, setReady] = useState(false);
  const [instancesList, setInstancesList] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState({});
  const [selectedInstanceError, setSelectedInstanceError] = useState(null);

  const [page, setPage] = useState(1);
  const [instancesPerPage, setInstancesPerPage] = useState(initialInstancesPerPage);
  const [totalInstances, setTotalInstances] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const { filter, filterChange } = useSearch();

  const [showEditForm, setShowEditForm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const loadInstances = (resetPage = false) => {
    const loadParams = {
      filter,
      skip: resetPage ? 0 : (page - 1) * instancesPerPage,
      limit: instancesPerPage,
    };

    if (timeout) {
      clearTimeout(timeout);
      requestIndex = `${new Date().getTime()}`;
    }

    const getRequest = (id) => {
      return loadInstancesServiceAction(loadParams)
        .then((result) => {
          // Do nothing if a new request has been triggered
          if (id !== requestIndex) {
            return;
          }

          setInstancesList(result.data);
          setTotalInstances(result.total);

          const numberOfPages = Math.ceil(result.total / instancesPerPage);
          setNumberOfPages(numberOfPages);
          
          if (resetPage) {
            setPage(1);
          }

          const _rangeStart = (page - 1) * instancesPerPage + 1
          setRangeStart(_rangeStart);
          setRangeEnd(_rangeStart + result.data.length - 1);
        })
        .finally(() => {
          setReady(true);
        });
    }

    timeout = setTimeout(() => getRequest(`${requestIndex}`), !ready ? 0 : 300);
  };

  const saveInstance = (instance) => {
    const error = validateInstanceBeforeSave
      ? validateInstanceBeforeSave(instance, instancesList)
      : null;
    if (error) {
      setSelectedInstanceError(error);
      return;
    }
    if (!saveInstanceServiceAction) {
      return;
    }
    saveInstanceServiceAction(instance)
      .then((result) => {
        if (result) {
          loadInstances();
          setShowEditForm(false);
        } else {
          setSelectedInstanceError('There was an error saving the instance');
          setSelectedInstance({});
        }
      });
  };

  const deleteInstance = (instance) => {
    setShowDeleteConfirm(false);
    if (!deleteInstanceServiceAction) {
      return;
    }
    deleteInstanceServiceAction(instance)
      .then((result) => {
        if (result) {
          setSelectedInstance({});
          loadInstances();
        } else {
          setSelectedInstanceError('There was an error deleting the instance');
          setSelectedInstance({});
        }
      });
  };

  useEffect(() => {
    if (!ready) {
      return;
    }
    loadInstances();
  }, [page]);

  useEffect(() => {
    loadInstances(true);
  }, [filter]);

  const handleAddButtonClicked = () => {
    setSelectedInstance({});
    setSelectedInstanceError(null);
    setShowEditForm(true);
  };

  const handleEditButtonClicked = _instance => {
    setSelectedInstance(_instance);
    setSelectedInstanceError(null);
    setShowEditForm(true);
  };

  const handleDeleteButtonClicked = _instance => {
    if (_instance) {
      setSelectedInstance(_instance);
    }
    setShowDeleteConfirm(true);
  };

  const handleCancelEditButtonClicked = _instance => {
    setSelectedInstance({});
    setShowEditForm(false);
  };

  return {
    ready,
    page,
    numberOfPages,
    instancesPerPage,
    totalInstances,
    selectedInstance,
    selectedInstanceError,
    showEditForm,
    showDeleteConfirm,
    filter,
    instancesList,
    rangeStart,
    rangeEnd,

    // Functions
    setPage,
    setInstancesPerPage,
    filterChange,
    saveInstance,
    deleteInstance,
    reloadInstances: loadInstances,
    setShowDeleteConfirm,

    handleAddButtonClicked,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
    handleDeleteButtonClicked,
  };
}

export default useBEDatatable;
