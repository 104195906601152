import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import { FormRow } from '../../styled/components';
import { TitleWithIcon } from './invoicing-system-config-success-setup.styled';

function InvoicingSystemConfigSuccessSetup() {
  return (
    <div>
      <FormRow>
        <TitleWithIcon variant="h4">
          <CheckIcon color="primary" fontSize="40px" />
          <span>Your invoicing system config is complete!</span>
        </TitleWithIcon>
      </FormRow>
      <FormRow>
        <Typography>
          You can now go to your <a href="/admin/services">services page</a> and start configuring them.
          Make sure you run the process for each of the imported companies.
        </Typography>
      </FormRow>
    </div>
  );
}

export default InvoicingSystemConfigSuccessSetup;
