import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Select,
  Typography,
  Toolbar,
  InputLabel,
  FormControl,
  CircularProgress,
  Autocomplete, Box,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import {
  DrawerActions,
  DrawerContent,
  DrawerSection,
  RightDrawer,
  Spacer,
} from 'styled/components';


import { getHousekeepingServices } from '../../api';
import { getApartments } from '../../api/apartment';
import { formatDateWithCustomTime } from '../../utils/date';
import { findHousekeepingReasons } from 'api/housekeeping-reasons';

const timePickerValues = [];
const dateHours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
const dateMinutes = ['00', '10', '20', '30', '40', '50'];
dateHours.forEach(hour => {
  dateMinutes.forEach(minutes => {
    timePickerValues.push(`${hour}:${minutes}`);
  });
});

function AddExtraHousekeepingProtocol({ handleClose, handleConfirm, error, open }) {
  const [ready, setReady] = useState(false);

  const [housekeepingReasonId, setHousekeepingReasonId] = useState('');
  const [housekeepingService, setHousekeepingService] = useState(undefined);
  const [apartment, setApartment] = useState(undefined);
  const [date, setDate] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState('09:00');
  const [timeTo, setTimeTo] = useState('17:00');
  const [description, setDescription] = useState('');

  const [housekeepingReasonOptions, setHousekeepingReasonOptions] = useState([]);
  const [housekeepingServicesOptions, setHousekeepingServicesOptions] = useState([]);
  const [apartmentOptions, setApartmentOptions] = useState([]);
  const [apartmentsLoading] = useState(false);

  useEffect(() => {
    const promises = [
      findHousekeepingReasons({skipPagination: true}),
      getHousekeepingServices(),
      getApartments(),
    ];
    Promise.all(promises)
      .then(([housekeepingReasons, housekeepingServices, apartments]) => {
        setHousekeepingReasonOptions(housekeepingReasons);
        setHousekeepingServicesOptions(housekeepingServices);
        setApartmentOptions(apartments);
        setReady(true);
      });
  }, []);

  const filterApartments = useCallback((options, { inputValue }) => {
    if (!inputValue || inputValue.length === 0) {
      return options;
    }
    return options.filter(
      apartment => apartment.fullAddress.toLowerCase().includes(inputValue.toLowerCase()),
    );
  }, []);

  const handleHousekeepingChange = ev => {
    const housekeepingService = housekeepingServicesOptions.find(
      hso => hso.id === ev.target.value,
    );
    setHousekeepingService(housekeepingService);
  };

  const handleApartmentChange = (ev, apartment) => {
    setApartment(apartment);
  };

  const handleAcceptClicked = () => {
    handleConfirm({
      description,
      apartmentId: apartment.id,
      HousekeepingServiceId: housekeepingService.id,
      ProtocolReasonId: housekeepingReasonId,
      from: formatDateWithCustomTime(date, timeFrom),
      to: formatDateWithCustomTime(date, timeTo),
    });
  };

  const title = `Schedule housekeeping item`;
  const isFormReady = housekeepingReasonId !== '' && housekeepingService && apartment && date;

  return (
    <RightDrawer
      open={open}
      onOpen={() => {
      }}
      onClose={handleClose}
      aria-labelledby="Add Invoice Item"
      anchor="right"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">{title}</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        {ready ? (
          <>
            <DrawerSection>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <InputLabel>Reason</InputLabel>
                <Select
                  value={housekeepingReasonId}
                  onChange={ev => setHousekeepingReasonId(ev.target.value)}
                >
                  {housekeepingReasonOptions.map(reason => (
                    <MenuItem key={reason} value={reason.id}>{reason.title}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
                <InputLabel>Housekeeping Service</InputLabel>
                <Select
                  value={housekeepingService ? housekeepingService.id : ''}
                  onChange={handleHousekeepingChange}
                  label="Housekeeping Service"
                >
                  {housekeepingServicesOptions.map(housekeepingService => (
                    <MenuItem
                      key={housekeepingService.id}
                      value={housekeepingService.id}
                    >
                      {housekeepingService.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                isOptionEqualToValue={(option, value) => option.id === value.id}
                options={apartmentOptions}
                loading={apartmentsLoading}
                onChange={handleApartmentChange}
                filterOptions={filterApartments}
                getOptionLabel={option => option.fullAddress}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Apartment"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {apartmentsLoading ?
                            <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    sx={{ margin: 0 }}
                  />
                )}
              />
              <DatePicker
                id="date"
                minDate={new Date()}
                value={date}
                onChange={setDate}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Date"
                    variant="standard"
                    autoComplete="false"
                    InputLabelProps={{ shrink: true }}
                    sx={{ width: '100%', margin: 0 }}
                  />
                )}
                inputFormat="dd/MM/yyyy"
                sx={{width: '100%'}}
              />
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                defaultValue={timeFrom}
                getOptionLabel={option => option}
                options={timePickerValues}
                onChange={(_, value) => setTimeFrom(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time From"
                    variant="standard"
                    sx={{ margin: 0 }}
                  />
                )}
                sx={{ flexGrow: 1 }}
                disableClearable
              />
              <Autocomplete
                isOptionEqualToValue={(option, value) => option === value}
                defaultValue={timeTo}
                getOptionLabel={option => option}
                options={timePickerValues}
                onChange={(_, value) => setTimeTo(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Time To"
                    variant="standard"
                    sx={{ margin: 0 }}
                  />
                )}
                sx={{ flexGrow: 1 }}
                disableClearable
              />
              <TextField
                multiline
                label="Description"
                value={description}
                onChange={ev => setDescription(ev.target.value)}
                variant="standard"
                sx={{ width: '100%', marginBottom: 3 }}
              />
            </DrawerSection>
          </>
        ) : null}
      </DrawerContent>
      <DrawerActions>
        <Button
          variant="contained"
          sx={{
            width: '100%',
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '1rem',
            columnGap: '0.25rem'
          }}
          disabled={!isFormReady}
          onClick={handleAcceptClicked}
        >
          <CheckIcon sx={{ width: '1.2rem' }} />
          <span>Save</span>
        </Button>
      </DrawerActions>
    </RightDrawer>
  );
}

export default AddExtraHousekeepingProtocol;
