const errorCodes = {
  DATE_NOT_REACHED: `This form can't be filled until`,
  STATE_NOT_REACHED: `This form can't be filled until the sales case is in status`,
}

export const parseCantBeDoneReason = (cantBeDoneReason) => {
  const parts = cantBeDoneReason.split(':');

  const [code, ...rest] = parts;
  if (parts.length < 2 || !errorCodes[code]) {
    return cantBeDoneReason;
  }

  return `${errorCodes[code]} ${rest.join(' ')}`;
}
