import { useContext } from 'react';
import MenuList from '@mui/material/MenuList';

import CollapsibleMenu from 'components/collapsible-menu';
import navLinkGroups from 'config/nav-links';
import { AppContext } from 'state/app-context';
import { systemRoles } from 'constants';

function NavItems({ onItemClick }) {
  const { currentUser } = useContext(AppContext);

  return (
    <MenuList>
      {navLinkGroups.map(group => {
        const hasPermissions = group.requiredRoles.every(role => currentUser.roles.includes(role))
          || currentUser.roles.includes(systemRoles.ADMIN);

        if (!hasPermissions) {
          return null;
        }

        return (
          <CollapsibleMenu
            {...group}
            key={group.title}
            onItemClick={onItemClick}
          />
        );
      })}
    </MenuList>
  );
}

export default NavItems;
