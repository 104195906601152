import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

const AppLayout = styled('div')`
  display: flex;
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.theme.palette.background.default};
`;

const AppMain = styled('main')`
  flex-grow: 1;
  position: relative;
  margin-top: 56px;
  overflow: auto;
  background: #F4F5FA;

  @media (min-width: 0px) and (orientation: landscape) {
    margin-top: 4px;
  }

  @media (min-width: 600px) {
    margin-top: 64px
  }
`;

const LeftDrawer = styled(Drawer)`
  width: ${props => props.theme.layout.drawerWidth}px;
  transition: width 0.15s;
  position: absolute;

  @media (min-width: 1200px) {
    position: relative;
  }
  
  &.drawerClosed {
    width: 0;
    transition: width 0.5s;
  }

  .MuiPaper-root {
    width: ${props => props.theme.layout.drawerWidth}px;
    margin-top: 56px;

    @media (min-width: 0px) and (orientation: landscape) {
      margin-top: 4px;
    }

    @media (min-width: 600px) {
      margin-top: 64px
    }
  }
`;

const LeftDrawerCompanySelector = styled('div')`
  padding: ${props => props.theme.spacing(2, 3)};
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  
  & > * {
    width: 100%;
  }
`;

const useStyles = makeStyles()(
  theme => ({
    AppBar: {
      zIndex: theme.zIndex.drawer + 1,
    }
  })
);

export { AppMain, AppLayout, LeftDrawer, LeftDrawerCompanySelector, useStyles };
