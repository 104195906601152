function padNumber(number, length = 2) {
  let result = `${number}`;
  while (result.length < length) {
    result = `0${result}`;
  }
  return result;
}

export function formatDate(date) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!date) {
    return 'NA';
  }
  return [
    padNumber(date.getDate()),
    padNumber(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export function formatTime(date) {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (!date) {
    return 'NA';
  }
  return [
    padNumber(date.getHours()),
    padNumber(date.getMinutes()),
  ].join(':');
}

export function formatDateTime(date) {
  return `${formatDate(date)} ${formatTime(date)}`;
}

export function formatDateWithCustomTime(date, time) {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const dateStr = [
    date.getFullYear(),
    padNumber(date.getMonth() + 1),
    padNumber(date.getDate()),
  ].join('/');

  return `${dateStr} ${time}`;
}

export function formatDateFrame(startDate, endDate) {
  if (!endDate) {
    return `${formatDateTime(startDate)} - N/A`;
  }
  if (areDatesTheSameDay(startDate, endDate)) {
    return `${formatDate(startDate)} ${formatTime(startDate)} - ${formatTime(endDate)}`;
  }
  return `${formatDateTime(startDate)} - ${formatDateTime(endDate)}`;
}

export function formatTimeFrame(startDate, endDate) {
  if (!endDate) {
    return `${formatTime(startDate)} - N/A`;
  }
  return `${formatTime(startDate)} - ${formatTime(endDate)}`;
}

export function areDatesTheSameDay(startDate, endDate) {
  if (typeof startDate === 'string') {
    startDate = new Date(startDate);
  }
  if (typeof endDate === 'string') {
    endDate = new Date(endDate);
  }
  return startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getDate() === endDate.getDate();
}

export function nightsBetween(startDate, endDate) {
  const _startDate = new Date(typeof startDate === 'string' ? startDate : startDate.getTime());
  _startDate.setHours(0);
  _startDate.setMinutes(0);
  _startDate.setSeconds(0);
  _startDate.setMilliseconds(0);
  const _endDate = new Date(typeof endDate === 'string' ? endDate : endDate.getTime());
  _endDate.setHours(0);
  _endDate.setMinutes(0);
  _endDate.setSeconds(0);
  _endDate.setMilliseconds(0);
  return Math.round((_endDate.getTime() - _startDate.getTime()) / (1000 * 3600 * 24));
}

export function areDatesInSameMonth(startDate, endDate) {
  const _startDate = new Date(typeof startDate === 'string' ? startDate : startDate.getTime());
  const _endDate = new Date(typeof endDate === 'string' ? endDate : endDate.getTime());
  return _endDate.getMonth() === _startDate.getMonth() && _endDate.getFullYear() === _startDate.getFullYear();
}
