import { Box, Switch, Typography, } from '@mui/material';

/**
 * Che
 * @param section
 * @param sectionValues
 * @param toggleSectionValue
 @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
function HousekeepingProtocolSingleAnswerSectionBody({ section, sectionValues, toggleSectionValue, disabled }) {
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
      <Box display="flex" alignItems="center" justifyContent="center" columnGap={1} color="error">
        <Typography fontWeight="bold">All the items in the section have been checked</Typography>
        <Switch
          size="small"
          disabled={disabled}
          checked={Boolean(sectionValues[`${section.id}-0`])}
          onChange={() => toggleSectionValue(`${section.id}-0`)}
        />
      </Box>
    </div>
  );
}

export default HousekeepingProtocolSingleAnswerSectionBody;
