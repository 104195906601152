import { useState } from 'react';

const useInputState = (defaultValue = '') => {
  const [value, setValue] = useState(defaultValue);

  return [
    value,
    {
      value,
      onChange: (ev) => setValue(ev.target.value),
    },
    setValue,
  ];
};

export default useInputState;
