import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const InvoiceHeaderColumn = styled(TableCell)`
  padding: 0;
`;

const InvoiceHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InvoiceDate = styled(Box)`
  display: flex;
  flex-direction: row;
  column-gap: ${props => props.theme.spacing(0.5)};
  align-items: center;
  justify-content: center;
  padding: ${props => props.theme.spacing(0.5, 1)};
`;

const EmptyPlaceholderContainer = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InteractiveRow = styled(TableRow)`
  cursor: pointer;
  &.selected, &:hover {
    background: ${props => props.theme.palette.grey['200']};
  }
  &.selected {
    & td {
      font-weight: bold;
    }
    & + tr {
      background: ${props => props.theme.palette.grey['100']};
    }
  }
`;

const ResponsiveSameRowFields = styled('div')`
  display: grid;

  @media all and (min-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 16px;
  }
`;

const SameRowFields = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
`;

const SameRowFlexFields = styled('div')`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const PriceTotal = styled(Typography)`
  text-align: right;
  font-weight: bold;
`;

export {
  InvoiceDate,
  InvoiceHeader,
  InvoiceHeaderColumn,
  EmptyPlaceholderContainer,
  InteractiveRow,
  ResponsiveSameRowFields,
  SameRowFields,
  SameRowFlexFields,
  PriceTotal,
};
