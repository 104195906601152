import { useEffect, useState } from 'react';

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Switch,
  Pagination,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';

import LoadingIndicator from 'components/loading-indicator';
import TableHeader from 'components/table-header';
import { AppContent, ContentRow } from 'styled/components';
import BulkEditPrices from './bulk-edit-prices';

import useDatatable from 'hooks/use-datatable';
import { getApartmentCategoryPrices, postApartmentCategoryPrices } from 'api/apartment-category-prices';

function Pricing() {
  const [onlyActive, setOnlyActive] = useState(true);

  const filterInstances = (instancesList, filter) => instancesList.filter(
    _instance => {
      return (!onlyActive || _instance.online) && _instance.name.toLowerCase().includes(filter.toLowerCase());
    }
  );

  const {
    ready,
    page,
    instancesList,
    filter,

    // Functions
    setPage,
    filterChange,
    reloadFilter,
    reloadInstances,
  } = useDatatable(getApartmentCategoryPrices, filterInstances);

  const [selected, setSelected] = useState({});
  const [showEditPrices, setShowEditPrices] = useState(false);

  useEffect(() => {
    const selectedTmp = { ...selected };
    instancesList.forEach(apartmentCategory => {
      if (selectedTmp[apartmentCategory.id]) {
        selectedTmp[apartmentCategory.id] = apartmentCategory;
      }
    });
    setSelected(selectedTmp);
  }, [instancesList]);

  useEffect(() => {
    if (ready) {
      reloadFilter();
    }
  }, [onlyActive]);

  if (!ready) {
    return <LoadingIndicator />;
  }

  const rangeStart = ((page - 1) * 50);
  const rangeEnd = Math.min(page * 50, instancesList.length);
  const numberOfPages = Math.ceil(instancesList.length / 50);
  const pageFilteredInstances = instancesList.filter((_instance, index) => {
    return index >= rangeStart && index <= rangeEnd;
  });

  const toggleSelectAll = () => {
    const result = {};
    if (Object.keys(selected).length !== pageFilteredInstances.length) {
      pageFilteredInstances.forEach(instance => {
        result[instance.id] = instance;
      });
    }
    setSelected(result);
  };

  const toggleSelectInstance = instance => {
    const result = { ...selected };
    if (result[instance.id]) {
      delete result[instance.id];
    } else {
      result[instance.id] = instance;
    }
    setSelected(result);
  };

  const selectAndEditPrices = (ev, instance) => {
    ev.preventDefault();
    ev.stopPropagation();
    setSelected({
      [instance.id]: instance,
    });
    setShowEditPrices(true);
  };

  const handleEditPrices = (prices) => {
    postApartmentCategoryPrices({
      ...prices,
      apartmentCategoryIds: Object.keys(selected).map(apartmentCategoryId => parseInt(apartmentCategoryId)),
    })
      .then(() => {
        reloadInstances();
        setShowEditPrices(false);
      });
  };

  const selectedCount = Object.keys(selected).length;

  return (
    <>
      <AppContent>
        <TableHeader
          title="Pricing"
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={instancesList.length}
        >
          <FormControlLabel
            labelPlacement="start"
            control={(
              <Switch
                size="small"
                checked={onlyActive}
                onChange={() => setOnlyActive(!onlyActive)}
              />
            )}
            label="Only active"
            sx={{ marginRight: 3 }}
          />
          {selectedCount > 0 && (
            <Button
              variant="contained"
              onClick={() => setShowEditPrices(true)}
            >
              Set Prices for {selectedCount} selected
            </Button>
          )}
        </TableHeader>
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: 0 }}>
                    <Checkbox
                      checked={Object.keys(selected).length === pageFilteredInstances.length}
                      onChange={toggleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Id</TableCell>
                  <TableCell>Apartment Category</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Rental Service</TableCell>
                  <TableCell sx={{ textAlign: 'center' }}>Base Rate</TableCell>
                  <TableCell sx={{ width: 30 }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageFilteredInstances.length > 0 && pageFilteredInstances.map((_instance) => (
                  <TableRow
                    key={_instance.id}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                    onClick={() => toggleSelectInstance(_instance)}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selected.hasOwnProperty(_instance.id)}
                      />
                    </TableCell>
                    <TableCell>{_instance.id}</TableCell>
                    <TableCell>{_instance.name}</TableCell>
                    <TableCell>{_instance.cityId === 1 ? 'Vienna' : 'Frankfurt'}</TableCell>
                    <TableCell>{_instance.prices.RentalService ? _instance.prices.RentalService.name : '-'}</TableCell>
                    <TableCell
                      sx={{ textAlign: 'center' }}>€ {_instance.prices.basePrice !== undefined ? _instance.prices.basePrice.toFixed(2) : '-'}</TableCell>
                    <TableCell>
                      <IconButton
                        size="small"
                        onClick={(ev) => selectAndEditPrices(ev, _instance)}
                      >
                        <ChevronRight />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                {pageFilteredInstances.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan="5">
                      <Typography>There are no Apartment categories to set the prices to</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
        {numberOfPages > 1 && (
          <ContentRow style={{ display: 'flex', justifyContent: 'center', columnGap: 16 }}>
            <Pagination
              count={numberOfPages}
              page={page}
              variant="text"
              shape="rounded"
              color="primary"
              showFirstButton
              showLastButton
              onChange={(ev, page) => setPage(page)}
            />
          </ContentRow>
        )}
      </AppContent>
      <BulkEditPrices
        handleClose={() => setShowEditPrices(false)}
        handleConfirm={handleEditPrices}
        error={undefined}
        selectedInstances={Object.values(selected)}
        open={showEditPrices}
      />
    </>
  );
}

export default Pricing;
