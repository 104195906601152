export const notSetOption = { id: 'not_set', label: 'Not set' };

export const yesNoOptions = [
  notSetOption,
  { id: 'yes', label: 'Yes' },
  { id: 'no', label: 'No' },
];

export const billingFrequencyOptions = [
  notSetOption,
  { id: 'ONE_TIME', label: 'One time' },
  { id: 'MONTHLY', label: 'Monthly' },
];

export const serviceType = {
  RENTAL: { id: 'RENTAL', label: 'Rental' },
  SERVICE_FEE: { id: 'SERVICE_FEE', label: 'Service fee' },
  FINAL_CLEANING: { id: 'FINAL_CLEANING', label: 'Final cleaning' },
  REGULAR: { id: 'REGULAR', label: 'Regular' },
}

export const serviceTypeOptions = [
  notSetOption,
  serviceType.RENTAL,
  serviceType.SERVICE_FEE,
  serviceType.FINAL_CLEANING,
  serviceType.REGULAR,
];

export const systemRoles = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  SALES: 'ROLE_SALES',
  HOUSEKEEPING: 'ROLE_HOUSEKEEPING'
}
