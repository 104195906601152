import { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import LoadingIndicator from '../../components/loading-indicator';
import { getDeposits, getDepositStatus, reactivateDeposit } from './api-services';
import { AppContent, ContentRow, InteractiveRow, ReactivateButton, Spacer } from './styled';

function RentalDeposits() {
  const [renderNumber, setRenderNumber] = useState(1);
  const [ready, setReady] = useState(false);
  const [instancesList, setInstancesList] = useState([]);
  const [instance, setInstance] = useState(null);
  const [filter, setFilter] = useState('');
  const [onlyActive, setOnlyActive] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();


  useEffect(() => {
    const urlFilter = searchParams.get('s');
    if (filter !== urlFilter) {
      setFilter(urlFilter || '');
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    getDeposits().then((result) => {
      if (mounted) {
        setInstancesList(result);
        setReady(true);
      }
    });
    return () => {
      mounted = false;
    };
  }, [renderNumber]);

  const filterChange = (ev) => {
    const filter = ev.target.value;

    // Update the search params
    const tmpSearchParams = Object.fromEntries(searchParams);
    if (filter.length > 0) {
      tmpSearchParams.s = filter;
    } else {
      delete tmpSearchParams.s;
    }
    setSearchParams(tmpSearchParams);

    // Update the filter in the state
    setFilter(filter);
  };

  const onReactivateDeposit = (deposit) => {
    reactivateDeposit(deposit).then(() => {
      setRenderNumber(renderNumber => renderNumber + 1);
    });
  };

  if (!ready) {
    return <LoadingIndicator />;
  }

  const filteredInstances = instancesList
    .filter(
      _instance =>
        `${_instance.salesCaseId}`.includes(filter.toLowerCase()) &&
        (!onlyActive || !_instance.expired)
    );

  return (
    <AppContent>
      <ContentRow>
        <Typography variant="h5" component="h1">Deposits</Typography>
        <Spacer />
        <FormControlLabel
          labelPlacement="start"
          control={
            <Switch checked={onlyActive} onChange={() => setOnlyActive(!onlyActive)} />
          }
          label="Only active"
          sx={{ marginRight: 3 }}
        />
        <TextField
          size="small"
          value={filter}
          onChange={filterChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </ContentRow>
      <ContentRow>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Sales case id</TableCell>
                <TableCell>City</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell sx={{ width: 32 }}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredInstances.length > 0 && filteredInstances.map((_instance) => (
                <Fragment key={_instance.id}>
                  <InteractiveRow
                    className={instance && instance.id === _instance.id ? 'selected' : ''}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                    onClick={() => setInstance(instance && instance.id === _instance.id ? null : _instance)}
                  >
                    <TableCell>{_instance.id}</TableCell>
                    <TableCell>{_instance.salesCaseId}</TableCell>
                    <TableCell>{_instance.countryCode === 'at' ? 'Vienna' : 'Frankfurt'}</TableCell>
                    <TableCell>
                      {getDepositStatus(_instance)}
                      {_instance.canBeReactivated && (
                        <ReactivateButton
                          size="small"
                          title="Reactivate this deposit"
                          onClick={ev => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            onReactivateDeposit(_instance);
                          }}
                        >
                          Reactivate
                        </ReactivateButton>
                      )}
                    </TableCell>
                    <TableCell>€ {_instance.amount}</TableCell>
                    <TableCell>
                      <IconButton size="small">
                        {instance && instance.id === _instance.id
                          ? <ExpandLessIcon />
                          : <ExpandMoreIcon />}
                      </IconButton>
                    </TableCell>
                  </InteractiveRow>
                  {instance && instance.id === _instance.id && (
                    <tr>
                      <td colSpan="6">
                        <Table sx={{ minWidth: 650, width: '100%' }} aria-label="simple table">
                          <TableHead>
                            <TableRow
                              sx={{ 'th': { paddingTop: 1, paddingBottom: 1 } }}
                            >
                              <TableCell>Intent Id</TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Date created</TableCell>
                              <TableCell>Last updated</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {_instance.paymentIntents.map((paymentIntent) => (
                              <TableRow
                                key={paymentIntent.paymentIntentId}
                                sx={{ 'td': { paddingTop: 1, paddingBottom: 1 } }}
                              >
                                <TableCell>{paymentIntent.paymentIntentId || '---'}</TableCell>
                                <TableCell>{paymentIntent.status}</TableCell>
                                <TableCell>{paymentIntent.dateCreated}</TableCell>
                                <TableCell>{paymentIntent.lastUpdated}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </td>
                    </tr>
                  )}
                </Fragment>
              ))}

              {filteredInstances.length === 0 && (
                <TableRow
                  sx={{ 'td': { paddingTop: 1, paddingBottom: 1 }, '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center" colSpan={4}>
                    <Typography>There are no deposits yet.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContentRow>
    </AppContent>
  );
}

export default RentalDeposits;
