import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import OutlinedInput from '@mui/material/OutlinedInput';

const AppContent = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${props => props.theme.spacing(3)};
`;

const ContentRow = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: ${props => props.theme.spacing(3)};
  }
`;

const RightDrawer = styled(SwipeableDrawer)`
  z-index: 1201 !important;
  
  .MuiPaper-root {
    width: ${props => props.theme.layout.editDrawerWidth}px;
    display: flex;
    flex-direction: column;
  }
`;

const DrawerContent = styled('div')`
  flex-grow: 1;
  overflow-y: auto;
`;

const DrawerActions = styled('div')`
  padding: 1rem;
`;

const DrawerSection = styled('div')`
  padding: 16px;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
  }
`;

const DrawerSectionForm = styled('div')`
  margin-top: 8px;
  display: flex;
  align-items: center;

  & > *:not(:first-child) {
    margin-left: 16px;
  }
`;

const Spacer = styled('div')`
  flex-grow: 1;
`;

const TableInput = styled(OutlinedInput)`
  & input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
`;

const TableLabel = styled('label')`
  cursor: pointer;
  display: block;
  width: 100%;
`;

const StyledPriceInput = styled(OutlinedInput)`
  width: 96px;
  & input {
    text-align: right;
  }
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export {
  AppContent,
  ContentRow,
  DrawerActions,
  DrawerContent,
  DrawerSection,
  DrawerSectionForm,
  StyledPriceInput,
  Spacer,
  TableInput,
  TableLabel,
  RightDrawer
};
