import { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Toolbar from '@mui/material/Toolbar';
import FormControl from '@mui/material/FormControl';

import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import { Spacer } from 'styled/components';
import { DrawerActions, DrawerContent, DrawerSection, RightDrawer, } from './styled';

function ModifyInstance({ instance, handleClose, handleConfirm, error, open }) {
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!instance) {
      return;
    }
    setTitle(instance.title || '');
  }, [instance]);

  const handleConfirmButtonClicked = () => {
    handleConfirm({
      ...(instance.id ? { id: instance.id } : {}),
      title
    });
  };

  const uiTitle = `${instance.id ? 'Edit' : 'Add'} Housekeeping protocol reason`;

  return (
    <RightDrawer
      open={open}
      onOpen={() => {
      }}
      onClose={handleClose}
      aria-labelledby={title}
      anchor="right"
    >
      <AppBar position="relative">
        <Toolbar>
          <Typography fontWeight="bold">{uiTitle}</Typography>
          <Spacer />
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: '#fff' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <DrawerContent>
        <DrawerSection>
          <FormControl sx={{ width: '100%', margin: 0 }} variant="standard">
            <TextField
              label="Title"
              value={title}
              variant="standard"
              onChange={(ev) => setTitle(ev.target.value)}
            />
          </FormControl>
        </DrawerSection>
      </DrawerContent>
      <DrawerActions>
        <Button
          variant="contained"
          sx={{
            width: '100%',
            marginTop: '1rem',
            display: 'flex',
            alignItems: 'center',
            lineHeight: '1rem',
            columnGap: '0.25rem'
          }}
          onClick={handleConfirmButtonClicked}
        >
          <CheckIcon sx={{ width: '1.2rem' }} />
          <span>Save</span>
        </Button>
      </DrawerActions>
    </RightDrawer>
  );
}

export default ModifyInstance;
