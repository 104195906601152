import invoicingSystemConfigErrorCodes from './invoicing-system-config.json';
import validationErrorCodes from './validations.json';

export const errorDomains = {
  VALIDATION: 'validation',
  INVOICING_SYSTEM_CONFIG: 'invoicing-system-config',
}

export function getErrorByDomain(domain, errorCode) {
  let message = undefined;
  if (domain === errorDomains.VALIDATION) {
    message = validationErrorCodes[errorCode];
  }
  if (domain === errorDomains.INVOICING_SYSTEM_CONFIG) {
    message = invoicingSystemConfigErrorCodes[errorCode];
  }
  return message || errorCode;
}
