import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { AppContent, ContentRow } from 'styled/components';
import TableHeader from 'components/table-header';
import ConfirmDeletion from 'components/confirm-deletion';
import LoadingIndicator from 'components/loading-indicator';
import useDatatable from 'hooks/use-datatable';

import ModifyInstance from './modify-instance';

import { deleteHousekeepingServices, getHousekeepingServices, saveHousekeepingServices } from '../../api/housekeeping-admin';

function Housekeeping() {
  const validateForm = (instance, _instancesList) => {
    const existingInstance = _instancesList.find(
      _instance => _instance.name === instance.name && _instance.id !== instance.id
    );
    return existingInstance
      ? 'The name is already taken.'
      : '';
  };

  const filterInstances = (instancesList, filter) => instancesList.filter(
    _instance => _instance.name.toLowerCase().includes(filter.toLowerCase()),
  );

  const {
    ready,
    rangeEnd,
    rangeStart,
    instancesList,
    selectedInstance,
    selectedInstanceError,
    showEditForm,
    showDeleteConfirm,
    filter,

    // Functions
    filterChange,
    saveInstance,
    deleteInstance,

    handleAddButtonClicked,
    handleEditButtonClicked,
    handleCancelEditButtonClicked,
    handleCancelDeleteButtonClicked,
  } = useDatatable(
    getHousekeepingServices,
    filterInstances,
    saveHousekeepingServices,
    validateForm,
    deleteHousekeepingServices,
  );

  if (!ready) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <AppContent>
        <TableHeader
          title="Housekeeping services"
          showAddButton
          handleAddButtonClicked={handleAddButtonClicked}
          filter={filter}
          filterChange={filterChange}
          firstItemIndex={rangeStart}
          lastItemIndex={rangeEnd}
          totalItems={instancesList.length}
        />
        <ContentRow>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>id</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Related services</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {instancesList.length > 0 && instancesList.map((_instance) => (
                  <TableRow
                    key={_instance.id}
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell>{_instance.id}</TableCell>
                    <TableCell>{_instance.name}</TableCell>
                    <TableCell>{_instance.description}</TableCell>
                    <TableCell>{_instance.Services.map(s => s.name).join(', ')}</TableCell>
                    <TableCell sx={{ width: 80 }} align="right">
                      <IconButton size="small" onClick={() => handleEditButtonClicked(_instance)}>
                        <ChevronRightIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}

                {instancesList.length === 0 && (
                  <TableRow
                    sx={{
                      'td': { paddingTop: 1, paddingBottom: 1 },
                      '&:last-child td, &:last-child th': { border: 0 }
                    }}
                  >
                    <TableCell align="center" colSpan={4}>
                      <Typography>There are no Housekeeping services yet. Add the first one by clicking the "Add new"
                        button</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </ContentRow>
      </AppContent>
      {showDeleteConfirm && (
        <ConfirmDeletion
          handleClose={handleCancelDeleteButtonClicked}
          handleConfirm={() => deleteInstance(selectedInstance)}
          deleteInstanceMsg={`the Service: ${selectedInstance.name}`}
        />
      )}
      <ModifyInstance
        handleClose={handleCancelEditButtonClicked}
        handleConfirm={saveInstance}
        error={selectedInstanceError}
        instance={selectedInstance}
        open={showEditForm}
      />
    </>
  );
}

export default Housekeeping;
